// AT-2018 | AT-2260 | Ankur Makavana | 03/08/2023 | create new screen for transformation
import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { EditOutlined } from "@ant-design/icons";
import logo from "../assets/images/aceN_Logo.png";
import loader from "../assets/images/loader.gif";
import ND_values from "./ND_values";
import { Select } from "antd";
import "antd/dist/antd.css";
import { PEButton, PEDiv } from "./permissionComponents/index";

function Update_nd_values(props) {
  const [loading, sendRequest] = useAxios();
  const [vendor_list, setvendor_list] = useState();
  const [ndevent_name, setndevent_name] = useState();
  const [ndEvent_list, setndEvent_list] = useState();
  const [vendor_id, setvendor_id] = useState();
  const [nd_vendor_fields, setnd_vendor_fields] = useState();
  const [ndid, setndid] = useState();
  const [nd_fields, setnd_fields] = useState();
  const [nd_fieldslist, setnd_fieldslist] = useState();
  const [VendorEvent_list, setVendorEvent_list] = useState();
  const [VendorEvent, setVendorEvent] = useState();
  const [viewList, setViewlist] = useState();
  const [view_id, setview_id] = useState();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [ndfieldVal, setNdfeildVal] = useState();
  const [vendorfieldVal, setVendorFieldval] = useState();
  const [vendorfieldName, setVendorfieldName] = useState();
  const [ndFieldName, setNdFieldName] = useState();
  let [ND_Event_Name, setNd_event_name] = useState();
  const [mapping_fields, setmapping_fields] = useState();
  const [mapping_ids, setmapping_ids] = useState([]);
  const [fieldlist, setfieldlist] = useState([
    {
      NDValue: "",
      VendorValue: "",
    },
  ]);
  const [isDisabled, setisDisabled] = useState(false);
  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    let { view_id } = props.location.state;

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/mapping/pair/data`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        let applist = [];
        response.map((app_list) =>
          applist.push({
            value: app_list._id,
            label: app_list.nd_event_name + " | " + app_list.vendor_event_name,
          })
        );
        setmapping_fields(applist);
        //setjiraTicket(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/all/nd/mapping/data?field_value_mapping_id=${view_id}`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setViewlist(response);
        setNdfeildVal(response[0]["nd_field_value"]);
        setVendorFieldval(response[0]["partner_field_value"]);
        setVendorfieldName(response[0]["partner_field_name"]);
        setNdFieldName(response[0]["nd_field_name"]);
        setmapping_ids(JSON.parse(response[0]["mapping_ids"]));
        //setjiraTicket(response);
      }
    );

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/vendor_id/list`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setvendor_list(response);
        //setjiraTicket(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/nd/id`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setndid(response[0].vendor_id);
        //setjiraTicket(response);
      }
    );
    if (ndid) {
      get_ndfields();
    }
  }, [ndid]);
  const handleInputChange = (e, index, api_index = null) => {
    const { name, value } = e.target;
    if (api_index === null) {
      const list = [...fieldlist];
      list[index][name] = value;
      setfieldlist(list);
    }
  };
  const handleRemoveClick = (index) => {
    const list = [...fieldlist];
    list.splice(index, 1);
    setfieldlist(list);
  };
  const handleAddfield = () => {
    setfieldlist([
      ...fieldlist,
      {
        NDValue: "",
        VendorValue: "",
      },
    ]);
  };
  const get_ndfields = async () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/vendor/events?vendor_id=` +
          ndid,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setndEvent_list(response);
        setNd_event_name(response[0]["event_name"]);

        //setjiraTicket(response);
      }
    );
  };
  const getvendor_event = (vendorid) => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/nd/mapping/data?vendor_event_id=` +
          vendorid +
          "&nd_event_id=" +
          ndevent_name,
        method: "GET",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setnd_fieldslist(response);
        if (response[0].event_config) {
          let parsedata = JSON.parse(response[0].event_config);
          const filteredData = parsedata.filter(
            (item) => item.isTransform == "1"
          );
          console.log("filteredData=>", filteredData);
          setnd_fieldslist(filteredData);
        }
        //setjiraTicket(response);
      }
    );
  };
  const save_mapping = () => {
    // setisDisabled(true);
    // let splitvalue = nd_vendor_fields.split("~");
    let apitoken = localStorage.getItem("api_token");
    let { view_id } = props.location.state;

    const form = new FormData();
    form.append("field_value_mapping_id", view_id);
    form.append("nd_field_value", ndfieldVal);
    form.append("partner_field_value", vendorfieldVal);
    form.append("mapping_ids", JSON.stringify(mapping_ids));
    // form.append("partner_field_name", splitvalue[1]);
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/set/nd/mapped/data`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setnd_fieldslist(response);
        setisErrOpen(true);
        setapi_error_message(response[0].message);
        setisDisabled(true);

        //setjiraTicket(response);
      }
    );
  };

  return (
    <div className="container">
      <Commonheader pagetitle={"Update ND values"} props={props} />
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                // window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>
      <div className="card-holder">
        <div className="row mt-4">
          <PEDiv element_id="updatend_event" className="form-group col-md-3">
            <label htmlFor="inputEmail4">ND Event</label>
            <input
              className="form-control"
              name="ndevent_name"
              value={ND_Event_Name}
              disabled
              //   disabled={view_id}

              onChange={(e) => {
                {
                  setndevent_name(e.target.value);
                  //get_ndevent_fields(e.target.value);
                }
              }}
            />
          </PEDiv>
          <PEDiv element_id="updatend_vendor" className="form-group col-md-3">
            <label htmlFor="inputEmail4">Vendor</label>
            <input
              className="form-control"
              name="vendor_id"
              value={vendorfieldName}
              disabled
              //   disabled={!ndevent_name}
              onChange={(e) => {
                setvendor_id(e.target.value);
                getvendor_event(e.target.value);
              }}
            />
          </PEDiv>
          <PEDiv element_id="updatend_ndfields" className="form-group col-md-3">
            <label htmlFor="inputEmail4">ND Fields</label>
            <input
              className="form-control"
              name="nd_fields"
              value={ndFieldName}
              disabled
              // disabled={!ndevent_name}
              onChange={(e) => {
                setnd_vendor_fields(e.target.value);
              }}
            />
          </PEDiv>
        </div>
        <div className="row mt-4">
          {fieldlist.map((fields, index) => (
            <>
              <div className="form-group col-md-12 row">
                <PEDiv element_id="updatend_ndvalue"  className="form-group col-md-4">
                  <label>ND Value</label>
                  <input
                    className="form-control"
                    name="NDValue"
                    value={ndfieldVal}
                    onChange={(e) => setNdfeildVal(e.target.value)}
                  />
                </PEDiv>
                <PEDiv element_id="updatend_vendorvalue" className="form-group col-md-4">
                  <label>Vendor Value</label>
                  <input
                    className="form-control"
                    name="VendorValue"
                    value={vendorfieldVal}
                    onChange={(e) => setVendorFieldval(e.target.value)}
                  />
                </PEDiv>
                <PEDiv element_id="updatend_mapping" className="form-group col-md-4">
                  <label htmlFor="inputEmail4">Mapping</label>
                  {console.log("mapping_ids->", mapping_ids)}
                  {console.log("mapping_fields->", mapping_fields)}
                  <Select
                    size="large"
                    mode="multiple"
                    placeholder="Select Mapping"
                    className="form-control"
                    value={mapping_ids && mapping_ids}
                    //onChange={(e) => handleInputMappingChange(e, index)}
                    onChange={(e) => setmapping_ids(e)}
                    options={mapping_fields}
                  />
                </PEDiv>
                {/* <div className="form-group col-md-2">
                   {fieldlist.length !== 1 && (
                    <>
                      <button
                        className="btn btn-danger form-group mr-2"
                        onClick={() => handleRemoveClick(index)}
                      >
                        -
                      </button>
                    </>
                  )} 
                </div> */}
              </div>
            </>
          ))}
        </div>
        <div className="text-center mt-2">
          <PEButton element_id="updatend_savemapping"
            className="btn btn-primary"
            disabled={isDisabled}
            onClick={save_mapping}
          >
            save
          </PEButton>
        </div>
      </div>
    </div>
  );
}
export default Update_nd_values;
