// AT-2018 | AT-2260 | Ankur Makavana | 03/08/2023 | create new screen for transformation
import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { EditOutlined } from "@ant-design/icons";
import logo from "../assets/images/aceN_Logo.png";
import loader from "../assets/images/loader.gif";
import { AiOutlineSearch } from "react-icons/ai";
import { Select } from "antd";
import "antd/dist/antd.css";
import {PEButton, PEDiv, PETh, PEThead} from './permissionComponents/index'

function ND_values(props) {
  const [loading, sendRequest] = useAxios();
  const [vendor_list, setvendor_list] = useState();
  const [ndevent_name, setndevent_name] = useState();
  const [ndEvent_list, setndEvent_list] = useState();
  const [vendor_id, setvendor_id] = useState();
  const [nd_vendor_fields, setnd_vendor_fields] = useState();
  const [ndid, setndid] = useState();
  const [nd_fields, setnd_fields] = useState();
  const [nd_fieldslist, setnd_fieldslist] = useState();
  const [VendorEvent_list, setVendorEvent_list] = useState();
  const [VendorEvent, setVendorEvent] = useState();
  const [viewList, setViewlist] = useState([]);
  const [view_id, setview_id] = useState();
  const [vendorName, setVName] = useState();
  const [filteredList, setFilteredList] = useState(viewList);
  const [searchQuery, setSearchQuery] = useState("");
  const [isErrOpen, setisErrOpen] = useState(false);
  const [originalList, setOriginalList] = useState([]);
  let [showsorting, setSorting] = useState(false);
  let [ndfieldname_sort, setndfieldname_sort] = useState(false);
  let [ndfieldvalue_sort, setndfieldvalue_sort] = useState(false);
  let [partnerfieldname_sort, setpartnerfieldname_sort] = useState(false);
  let [partnerfieldvalue_sort, setpartnerfieldvalue_sort] = useState(false);
  let [createdat_sort, setcreatedat_sort] = useState(false);
  const [filters, setFilters] = useState({});
  const [api_error_message, setapi_error_message] = useState();
  const [mapping_fields, setmapping_fields] = useState();
  const [fieldlist, setfieldlist] = useState([
    {
      NDValue: "",
      VendorValue: "",
      mapping_ids: "",
    },
  ]);
  const [isDisabled, setisDisabled] = useState(false);
  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/all/nd/mapping/data`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        response.sort(function(a, b) {
          //sorting by date
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setViewlist(response);
        setOriginalList(response);
        //setjiraTicket(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/vendor_id/list`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setvendor_list(response);
        //setjiraTicket(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/mapping/pair/data`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        let applist = [];
        response.map((app_list) =>
          applist.push({
            value: app_list._id,
            label: app_list.nd_event_name + " | " + app_list.vendor_event_name,
          })
        );
        setmapping_fields(applist);
        //setjiraTicket(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/nd/id`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setndid(response[0].vendor_id);
        //setjiraTicket(response);
      }
    );
    if (ndid) {
      get_ndfields();
    }
  }, [ndid]);
  const handleInputChange = (e, index, api_index = null) => {
    const { name, value } = e.target;
    if (api_index === null) {
      const list = [...fieldlist];
      list[index][name] = value;
      setfieldlist(list);
    }
  };

  const handleInputMappingChange = (e, index) => {
    const list = [...fieldlist];
    list[index]["mapping_ids"] = e;
    setfieldlist(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...fieldlist];
    list.splice(index, 1);
    setfieldlist(list);
  };
  const handleAddfield = () => {
    setfieldlist([
      ...fieldlist,
      {
        NDValue: "",
        VendorValue: "",
        mapping_ids: "",
      },
    ]);
  };
  const get_ndfields = async () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/vendor/events?vendor_id=` +
          ndid,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setndEvent_list(response);
        //setjiraTicket(response);
      }
    );
  };
  const getvendor_event = (vendorid) => {
    setnd_fieldslist("");
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/nd/mapping/data?vendor_event_id=` +
          vendorid +
          "&nd_event_id=" +
          ndevent_name,
        method: "GET",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        //setnd_fieldslist(response[0].event_config);
        if (response[0].event_config.length > 0) {
          const transformedEventData = JSON.parse(
            response[0].event_config
          ).filter((item) => item.isTransform == "1");
          // .map(
          //   (configStr) => {
          //     const config = configStr;
          //     //console.log("response[0].event_config", config);
          //     return config.filter((item) => item.isTransform == "1");
          //   }
          // );

          // Flatten the filtered data into a single array
          const flattenedData = [].concat(...transformedEventData);
          console.log("flattenedData-->", transformedEventData);
          setnd_fieldslist(flattenedData);
        }
        //setjiraTicket(response);
      }
    );
  };

  //---Applied Filter
  const handleSearch = (event) => {
    const query = event.target.value;
    console.log(query);
    setSearchQuery(query);
    if (query.length == 0) {
      let apitoken = localStorage.getItem("api_token");

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/get/all/nd/mapping/data`,
          method: "GET",
          //data: inputdata,
          headers: {
            "Content-Type": "application/json",
            "api-token": apitoken,
          },
        },
        (response) => {
          response.sort(function(a, b) {
            //sorting by date
            return new Date(b.created_at) - new Date(a.created_at);
          });
          setViewlist(response);
          //setjiraTicket(response);
        }
      );
    }

    const searchList = viewList.filter((item) => {
      console.log(item);
      return item.vendor_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setViewlist(searchList);
    // setFilteredList(searchList);
  };

  const filterData = () => {
    return viewList.filter((row) => {
      for (const columnName in filters) {
        const filterText = filters[columnName].toLowerCase();
        const cellText = String(row[columnName]).toLowerCase();
        if (!cellText.includes(filterText)) {
          return false;
        }
      }
      return true;
    });
  };
  const handleSearchSort = (e, columnName) => {
    const updatedFilters = { ...filters, [columnName]: e.target.value };
    setFilters(updatedFilters);
  };
  let sortndFieldName = (event) => {
    const query = event.target.value;
    console.log(query);
    setSearchQuery(query);
    console.log(query.length, "Length --");
    const searchList = viewList.filter((item) => {
      console.log(item, "Fltering in sortndFieldName");
      return (
        item.nd_field_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
    setViewlist(searchList);
    if (query.length == 0) {
      if (viewList.length == 1) {
        viewList.pop();

        setViewlist(originalList);
      }

      // setViewList(searchList)
      // window.location.reload()
      // load()
      console.log(originalList, "When search length is 0");
      setViewlist(originalList);
    }
  };
  let sortndFieldValue = (event) => {
    const query = event.target.value;
    console.log(query);
    setSearchQuery(query);
    console.log(query.length, "Length --");
    const searchList = viewList.filter((item) => {
      console.log(item, "Fltering in sortndFieldName");
      return (
        item.nd_field_value.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
    setViewlist(searchList);
    if (query.length == 0) {
      if (viewList.length == 1) {
        viewList.pop();

        setViewlist(originalList);
      }

      // setViewList(searchList)
      // window.location.reload()
      // load()
      console.log(originalList, "When search length is 0");
      setViewlist(originalList);
    }
  };

  let partnerfieldname = (event) => {
    const query = event.target.value;
    console.log(query);
    setSearchQuery(query);
    console.log(query.length, "Length --");
    const searchList = viewList.filter((item) => {
      console.log(item, "Fltering in sortndFieldName");
      return (
        item.partner_field_name.toLowerCase().indexOf(query.toLowerCase()) !==
        -1
      );
    });
    setViewlist(searchList);
    if (query.length == 0) {
      if (viewList.length == 1) {
        viewList.pop();

        setViewlist(originalList);
      }

      // setViewList(searchList)
      // window.location.reload()
      // load()
      console.log(originalList, "When search length is 0");
      setViewlist(originalList);
    }
  };

  let partnerfieldvalue = (event) => {
    const query = event.target.value;
    console.log(query);
    setSearchQuery(query);
    console.log(query.length, "Length --");
    const searchList = viewList.filter((item) => {
      console.log(item, "Fltering in sortndFieldName");
      return (
        item.partner_field_value.toLowerCase().indexOf(query.toLowerCase()) !==
        -1
      );
    });
    setViewlist(searchList);
    if (query.length == 0) {
      if (viewList.length == 1) {
        viewList.pop();

        setViewlist(originalList);
      }

      // setViewList(searchList)
      // window.location.reload()
      // load()
      console.log(originalList, "When search length is 0");
      setViewlist(originalList);
    }
  };
  let createdat = (event) => {
    const query = event.target.value;
    console.log(query);
    setSearchQuery(query);
    console.log(query.length, "Length --");
    const searchList = viewList.filter((item) => {
      console.log(item, "Fltering in sortndFieldName");
      return item.created_at.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setViewlist(searchList);
    if (query.length == 0) {
      if (viewList.length == 1) {
        viewList.pop();

        setViewlist(originalList);
      }

      // setViewList(searchList)
      // window.location.reload()
      // load()
      console.log(originalList, "When search length is 0");
      setViewlist(originalList);
    }
  };
  let blurEvent = () => {
    setSorting(false);
    setndfieldname_sort(false);
    setndfieldvalue_sort(false);
    setpartnerfieldname_sort(false);
    setpartnerfieldvalue_sort(false);
    setcreatedat_sort(false);
  };
  const save_mapping = () => {
    setisDisabled(true);
    let splitvalue = nd_vendor_fields.split("~");
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();
    form.append("vendor_id", vendor_id);
    form.append("nd_and_vendor_value_json", JSON.stringify(fieldlist));
    form.append("nd_field_name", splitvalue[0]);
    form.append("partner_field_name", splitvalue[1]);
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/set/nd/mapped/data`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setnd_fieldslist(response);
        setisErrOpen(true);
        setapi_error_message(response[0].message);
        //setjiraTicket(response);
      }
    );
  };
  const filteredData = filterData();
  // console.log("fieldlist", fieldlist);
  return (
    <div className="container">
      <Commonheader pagetitle={"ND values"} props={props} />
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>
      <div className="card-holder">
        <div className="row mt-4">
          <PEDiv element_id="ndvalue_ndevent" className="form-group col-md-3">
            <label htmlFor="inputEmail4">ND Event</label>
            <select
              className="form-control"
              name="ndevent_name"
              value={ndevent_name}
              disabled={view_id}
              onChange={(e) => {
                {
                  setndevent_name(e.target.value);
                  setvendor_id("");
                  setnd_vendor_fields("");
                  //get_ndevent_fields(e.target.value);
                }
              }}
            >
              <option value="">Select Event</option>
              {ndEvent_list &&
                ndEvent_list.map((ndeve) => (
                  <option value={ndeve.vendor_event_id}>
                    {ndeve.event_name}
                  </option>
                ))}
            </select>
          </PEDiv>
          <PEDiv element_id="ndvalue_vendor" className="form-group col-md-3">
            <label htmlFor="inputEmail4">Vendor</label>
            <select
              className="form-control"
              name="vendor_id"
              value={vendor_id}
              disabled={!ndevent_name}
              onChange={(e) => {
                setvendor_id(e.target.value);
                getvendor_event(e.target.value);
              }}
            >
              <option value="">Select Vendor</option>
              {vendor_list &&
                vendor_list.map((vendordata) => (
                  <option value={vendordata.vendor_id}>
                    {vendordata.vendor_name}
                  </option>
                ))}
            </select>
          </PEDiv>
          <PEDiv element_id="ndvalue_dnfields" className="form-group col-md-3">
            <label htmlFor="inputEmail4">ND Fields</label>
            <select
              className="form-control"
              name="nd_vendor_fields"
              value={nd_vendor_fields}
              disabled={!vendor_id}
              onChange={(e) => {
                setnd_vendor_fields(e.target.value);
              }}
            >
              <option value="">Select ND Fields</option>
              {nd_fieldslist &&
                nd_fieldslist.map((fieldlist) => {
                  //const fieldData = JSON.parse(fieldlist);

                  console.log("obj.isTransform-->", fieldlist);
                  if (fieldlist.isTransform == "1") {
                    return (
                      <option
                        value={
                          fieldlist.NDFields + "~" + fieldlist.VendorFields
                        }
                      >
                        {fieldlist.NDFields}
                      </option>
                    );
                  }
                })}
            </select>
          </PEDiv>
          {nd_vendor_fields && (
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Vendor Fields</label>
              <div>{nd_vendor_fields.split("~")[1]}</div>
            </div>
          )}
        </div>
        <div className="row mt-4">
          {fieldlist.map((fields, index) => (
            <>
              <div className="form-group col-md-12 row">
                <PEDiv element_id="ndvalue_ndvalue" className="form-group col-md-3">
                  <label>ND Value</label>
                  <input
                    className="form-control"
                    name="NDValue"
                    value={fields.NDValue}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </PEDiv>
                <PEDiv element_id="ndvalue_vendorvalue" className="form-group col-md-3">
                  <label>Vendor Value</label>
                  <input
                    className="form-control"
                    name="VendorValue"
                    value={fields.VendorValue}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </PEDiv>
                <PEDiv element_id="ndvalue_mapping" className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Mapping</label>
                  <Select
                    size="large"
                    mode="multiple"
                    placeholder="Select Mapping"
                    className="form-control"
                    //defaultValue={fields.mapping_ids}
                    onChange={(e) => handleInputMappingChange(e, index)}
                    options={mapping_fields}
                  />
                </PEDiv>
                <div className="form-group col-md-2">
                  {fieldlist.length !== 1 && (
                    <>
                      <button
                        className="btn btn-danger form-group mr-2"
                        onClick={() => handleRemoveClick(index)}
                      >
                        -
                      </button>
                    </>
                  )}
                  {fieldlist.length - 1 === index && (
                    <>
                      <button
                        className="btn btn-primary form-group"
                        onClick={handleAddfield}
                      >
                        +
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="text-center mt-2">
          <PEButton element_id="ndvalue_save"
            className="btn btn-primary"
            disabled={isDisabled}
            onClick={save_mapping}
          >
            save
          </PEButton>
        </div>
      </div>

      <div className="mt-4">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <PETh element_id="ndvalue_vendorname" scope="col">
                Vendor Name
                <br />
                <input
                  placeholder="Search Vendor Name"
                  name="search"
                  onBlur={blurEvent}
                  className="form-control"
                  type="text"
                  value={filters["vendor_name"]}
                  onChange={(e) => handleSearchSort(e, "vendor_name")}
                />
              </PETh>
              <PETh element_id="ndvalue_"ndfiledname scope="col">
                nd_field_name
                <br />
                <input
                  placeholder="Search Vendor Name"
                  name="search"
                  onBlur={blurEvent}
                  className="form-control"
                  type="text"
                  value={filters["nd_field_name"]}
                  onChange={(e) => handleSearchSort(e, "nd_field_name")}
                />
              </PETh>
              <PETh element_id="ndvalue_ndfieldvalue" scope="col">
                nd_field_value
                <br />
                <input
                  placeholder="Search Vendor Name"
                  name="search"
                  onBlur={blurEvent}
                  className="form-control"
                  type="text"
                  value={filters["nd_field_value"]}
                  onChange={(e) => handleSearchSort(e, "nd_field_value")}
                />
              </PETh>
              <PETh element_id="ndvalue_partnerfieldname" scope="col">
                partner_field_name
                <br />
                <input
                  placeholder="Search Vendor Name"
                  name="search"
                  onBlur={blurEvent}
                  className="form-control"
                  type="text"
                  value={filters["partner_field_name"]}
                  onChange={(e) => handleSearchSort(e, "partner_field_name")}
                />
              </PETh>
              <PETh element_id="ndvalue_partnerfieldvalue" scope="col">
                partner_field_value
                <br />
                <input
                  placeholder="Search Vendor Name"
                  name="search"
                  onBlur={blurEvent}
                  className="form-control"
                  type="text"
                  value={filters["partner_field_value"]}
                  onChange={(e) => handleSearchSort(e, "partner_field_value")}
                />
              </PETh>
              <PETh  element_id="ndvalue_createdat" scope="col">
                created_at
                <br />
                <input
                  placeholder="Search Vendor Name"
                  name="search"
                  onBlur={blurEvent}
                  className="form-control"
                  type="text"
                  value={filters["created_at"]}
                  onChange={(e) => handleSearchSort(e, "created_at")}
                />
              </PETh>
              <PETh element_id="ndvalue_action" scope="col">Action</PETh>
            </tr>
          </thead>
          <tbody>
            {filteredData &&
              filteredData.map((list) => (
                <tr>
                  <td>{list.vendor_name}</td>
                  <td>{list.nd_field_name}</td>
                  <td>{list.nd_field_value}</td>
                  <td>{list.partner_field_name}</td>
                  <td>{list.partner_field_value}</td>
                  <td>{list.created_at}</td>

                  <td>
                    <PEButton element_id="ndvalue_save"
                      type="button"
                      class="btn btn-outline-info"
                      onClick={() =>
                        props.history.push({
                          pathname: `update_nd_value`,
                          state: {
                            view_id: list.field_value_mapping_id,
                          },
                        })
                      }
                    >
                      <EditOutlined />
                    </PEButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ND_values;
