import React, { useEffect, useState, useRef } from "react";
import { JSONTree } from "react-json-tree";
import { Dropdown } from "antd";
import "antd/dist/antd.css";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const theme = {
  scheme: "solarized",
  author: "ethan schoonover (http://ethanschoonover.com/solarized)",
  base00: "#002b36",
  base01: "#073642",
  base02: "#586e75",
  base03: "#657b83",
  base04: "#839496",
  base05: "#93a1a1",
  base06: "#eee8d5",
  base07: "#fdf6e3",
  base08: "#dc322f",
  base09: "#cb4b16",
  base0A: "#b58900",
  base0B: "#859900",
  base0C: "#2aa198",
  base0D: "#268bd2",
  base0E: "#6c71c4",
  base0F: "#d33682",
};

const EditableJsonTree = ({
  data,
  onChange,
  editEnabled,
  ValueRenderer,
  LabelRenderer,
  NestedValueRenderer,
}) => {
  const [editedData, setEditedData] = useState(data);
  const [history, setHistory] = useState([JSON.parse(JSON.stringify(data))]);
  const [historyIndex, setHistoryIndex] = useState(0);

  editEnabled = editEnabled ? true : false;

  const rootRef = useRef();

  if (!onChange) {
    // useEffect(()=>{
    //   onChange(editedData)
    // },[editedData])
    onChange = () => {};
  }

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const updateHistory = (data) => {
    setHistory((prevHistory) => [
      ...prevHistory,
      JSON.parse(JSON.stringify(data)),
    ]);
    setHistoryIndex((prevIndex) => prevIndex + 1);
  };

  // const handleUndo = () => {
  //   if (historyIndex > 0) {
  //     setEditedData(history[historyIndex - 1]);
  //     setHistoryIndex((prevIndex) => prevIndex - 1);
  //   }
  // };

  // const handleRedo = () => {
  //   if (historyIndex < history.length - 1) {
  //     setEditedData(history[historyIndex + 1]);
  //     setHistoryIndex((prevIndex) => prevIndex + 1);
  //   }
  // };

  const handleKeyEdit = (path, oldKey, newKey) => {
    const updatedData = { ...editedData };

    let currentLevel = updatedData;
    path
      .reverse()
      .slice(0, -1)
      .forEach((key) => {
        currentLevel = currentLevel[key];
      });

    let oldValue = currentLevel[oldKey];
    const keyIndex = Object.keys(currentLevel).indexOf(oldKey);

    if (keyIndex !== -1) {
      const keys = Object.keys(currentLevel);
      keys.splice(keyIndex, 1, newKey);

      let reorderedObject = keys.reduce((obj, key) => {
        obj[key] = currentLevel[key];
        return obj;
      }, {});

      reorderedObject[newKey] = oldValue;

      Object.keys(currentLevel).forEach((keyToDelete) => {
        delete currentLevel[keyToDelete];
      });

      // Assign all keys from reorderedObject one by one
      const reorderedObjectKeys = Object.keys(reorderedObject);
      reorderedObjectKeys.forEach((keyToAssign) => {
        currentLevel[keyToAssign] = reorderedObject[keyToAssign];
      });

      setEditedData(updatedData);
      onChange(updatedData);
    }
    updateHistory(updatedData);
  };

  const updateJsonValue = (path, value) => {
    const updatedJson = { ...editedData };
    let currentObj = updatedJson;
    path.reverse();
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      currentObj = currentObj[key];
    }
    currentObj[path[path.length - 1]] = value;
    // console.log(updatedJson)
    setEditedData(updatedJson);
    onChange(updatedJson);
    updateHistory(updatedJson);
  };

  const insertJsonKey = (path, newKey, newValue) => {
    const updatedJson = { ...editedData };
    let currentObj = updatedJson;
    path.reverse();

    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      currentObj = currentObj[key];
    }

    if (path.length == 0) {
      currentObj[newKey] = newValue;
    } else {
      const lastKey = path[path.length - 1];
      if (Array.isArray(currentObj[lastKey])) {
        currentObj[lastKey].push(newValue);
      } else {
        currentObj[lastKey] = {
          ...currentObj[lastKey],
          [newKey]: newValue,
        };
      }
    }

    setEditedData(updatedJson);
    onChange(updatedJson);
    updateHistory(updatedJson);
  };

  const deleteJsonKey = (path) => {
    const updatedJson = { ...editedData };
    path = path.reverse();
    let currentObj = updatedJson;
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      if (currentObj.hasOwnProperty(key)) {
        currentObj = currentObj[key];
      } else {
        return;
      }
    }

    const lastKey = path[path.length - 1];
    if (currentObj.hasOwnProperty(lastKey)) {
      if (Array.isArray(currentObj)) {
        currentObj.splice(lastKey, 1);
      } else {
        delete currentObj[lastKey];
      }
      setEditedData(updatedJson);
      onChange(updatedJson);
    } else {
    }

    updateHistory(updatedJson);
  };

  const handleConfigChange = (path, config) => {
    updateJsonValue(path, JSON.stringify(config));
  };

  const addItemIntoKey = (keyPath, datatype) => {
    let value = null;
    if (datatype === "Array") {
      value = [];
    } else if (datatype === "Object") {
      value = {};
    } else if (datatype === "String") {
      value = JSON.stringify({
        is_mandatory: "n",
        status: "A",
        field_datatype: "textbox",
      });
    }
    insertJsonKey(keyPath, "__NEW_KEY__", value);
  };

  const addItemAfterKey = (keyPath, datatype) => {
    let value = null;
    if (datatype === "Array") {
      value = [];
    } else if (datatype === "Object") {
      value = {};
    } else if (datatype === "String") {
      value = JSON.stringify({
        is_mandatory: "n",
        status: "A",
        field_datatype: "textbox",
      });
    }
    keyPath.splice(0, 1);
    insertJsonKey(keyPath, "__NEW_KEY__", value);
  };

  const labelRenderer = (keyPath, nodeType, expanded, expandable) => {
    const key = keyPath[0];
    // console.log(nodeType)
    return LabelRenderer ? (
      <LabelRenderer
        path={keyPath}
        keyName={key}
        type={nodeType}
        expanded={expanded}
      />
    ) : (
      <EditableKeyRenderer
        path={keyPath}
        keyName={key}
        type={nodeType}
        onKeyEdit={handleKeyEdit}
        addItemIntoKey={addItemIntoKey}
        addItemAfterKey={addItemAfterKey}
        expanded={expanded}
        rootRef={rootRef}
        deleteJsonKey={deleteJsonKey}
        editEnabled={editEnabled}
      />
    );
  };

  const valueRenderer = (valueAsString, value, ...keyPath) => {
    return (
      <ValueRenderer
        jsonConfig={value}
        path={keyPath}
        valueType={typeof value}
        onFormSubmit={handleConfigChange}
      />
    );
  };

  const getValueString = NestedValueRenderer
    ? (type, data, itemType, itemString, keyPath) => {
        return (
          <NestedValueRenderer
            path={keyPath}
            valueType={typeof data}
            value={data}
            itemString={itemString}
            itemType={itemType}
          />
        );
      }
    : (type, data, itemType, itemString, keyPath) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {itemType}
          </span>
        );
      };

  return (
    <div ref={rootRef}>
      <JSONTree
        theme={{
          extend: theme,
          tree: {
            padding: "20px",
            borderRadius: "7px",
          },
          nestedNodeItemString: ({ style }, keyPath, nodeType, expanded) => ({
            style: {
              ...style,
              paddingLeft: "0.5em",
              cursor: "default",
              color: theme.base03,
            },
          }),
        }}
        data={editedData}
        // sortObjectKeys={true}
        shouldExpandNodeInitially={() => true}
        hideRoot
        // invertTheme={true}
        getItemString={getValueString}
        labelRenderer={labelRenderer}
        valueRenderer={valueRenderer}
      />
      {/* <button onClick={handleUndo} disabled={historyIndex <= 0}>
        Undo
      </button>
      <button onClick={handleRedo} disabled={historyIndex >= history.length - 1}>
        Redo
      </button> */}
    </div>
  );
};

const EditableKeyRenderer = ({
  path,
  keyName,
  type,
  onKeyEdit,
  addItemAfterKey,
  addItemIntoKey,
  expanded,
  rootRef,
  deleteJsonKey,
  editEnabled,
}) => {
  const [editedKey, setEditedKey] = useState(
    keyName === "__NEW_KEY__" ? "newKey" : keyName
  );
  const [isEdit, setIsEdit] = useState(false);
  const [inputWidth, setInputWidth] = useState("auto");
  const [editPosition, setEditPosition] = useState(null);
  const noderef = useRef();
  const spanref = useRef();

  useEffect(() => {
    const parentRect = rootRef.current.getBoundingClientRect();
    const childRect = noderef.current.getBoundingClientRect();

    const top = childRect.top / 100;
    const left = parentRect.left - childRect.left + 10;

    setEditPosition({ top, left });
  }, []);

  useEffect(() => {
    adjustInputWidth(editedKey);
    if (keyName === "__NEW_KEY__") {
      setIsEdit(true);
    }
  }, [editedKey]);

  const adjustInputWidth = (text) => {
    // Create a hidden measure element
    const hiddenMeasureElement = document.createElement("div");
    hiddenMeasureElement.style.position = "absolute";
    hiddenMeasureElement.style.visibility = "hidden";
    hiddenMeasureElement.style.whiteSpace = "nowrap";
    hiddenMeasureElement.textContent = text;

    // Append the measure element to the document body
    document.body.appendChild(hiddenMeasureElement);

    // Get the measured width
    let measuredWidth = hiddenMeasureElement.offsetWidth;

    // Remove the measure element
    document.body.removeChild(hiddenMeasureElement);

    measuredWidth += 5;
    // Apply the measured width to the input box

    setInputWidth(measuredWidth > 10 ? measuredWidth : 10 + "px");
  };
  const handleKeyChange = (e) => {
    setEditedKey(e.target.value);
  };

  const handleKeyBlur = () => {
    setIsEdit(!isEdit);
    if (editedKey !== keyName) {
      onKeyEdit(path, keyName, editedKey);
    }
  };

  const handleMenuClick = (option) => {
    const value = option.split("-")[0];
    const datatypes = ["Array", "Object", "String"];

    if (value === "2") {
      addItemAfterKey(path, datatypes[parseInt(option.split("-")[1]) - 1]);
    } else if (value === "1") {
      addItemIntoKey(path, datatypes[parseInt(option.split("-")[1]) - 1]);
      if (!expanded) {
        spanref.current.click();
      }
    }
  };

  const items = [
    {
      label: `Insert after ${keyName}`,
      key: "2",
      children: [
        {
          key: "2-1",
          label: "Array",
        },
        {
          key: "2-2",
          label: "Object",
        },
        {
          key: "2-3",
          label: "String",
        },
      ],
    },
  ];

  if (type === "Array" || type == "Object") {
    items.splice(0, 0, {
      label: `Insert into ${keyName}`,
      key: "1",
      children: [
        {
          key: "1-1",
          label: "Array",
        },
        {
          key: "1-2",
          label: "Object",
        },
        {
          key: "1-3",
          label: "String",
        },
      ],
    });
  }
  return (
    <div className="json-label-container">
      <div
        className="edit-action-container"
        style={
          !editEnabled ? { visibility: "hidden", pointerEvents: "none" } : {}
        }
      >
        <DeleteOutlined
          className="anticon-delete"
          onClick={(e) => {
            e.stopPropagation();
            deleteJsonKey(path);
          }}
        />
        <DropDownMenu onClick={handleMenuClick} items={items} />
      </div>
      {!isEdit && (
        <div className="json-key-name" ref={noderef}>
          <span ref={spanref}></span>
          <span
            onClick={() => {
              setIsEdit(!(typeof keyName === "number") && editEnabled);
            }}
          >
            {" "}
            {keyName}:
          </span>
        </div>
      )}

      {isEdit && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleKeyBlur();
          }}
          style={{ display: "inline", paddingLeft: "10px" }}
        >
          <input
            className="json-editable-input-box"
            type="text"
            value={editedKey}
            style={{ width: inputWidth }}
            onChange={handleKeyChange}
            onBlur={handleKeyBlur}
            disabled={!path.length}
          />
          :
        </form>
      )}
    </div>
  );
};

const DropDownMenu = ({ items, onClick }) => {
  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
    onClick(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <div style={{ display: "inline" }}>
      <Dropdown menu={menuProps} trigger={["click"]}>
        <a
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <PlusCircleOutlined className="anticon-plus" />
        </a>
      </Dropdown>
    </div>
  );
};

export default EditableJsonTree;
