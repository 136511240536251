// // src/components/ResultsModal.js
// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import "../assets/css/Element_permissions.css";

// const ResultsModal = ({ show, onHide, results }) => {
//   return (

//     <Modal show={show} onHide={onHide}>
//       <Modal.Header closeButton>
//         <Modal.Title>Users</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {console.log(results)}

//         {results.length > 0 ? (

//              <table className="results-table">
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Username</th>

//             </tr>
//           </thead>
//           <tbody>
//             {results.map((result, index) => (
//               <tr key={index}>
//                  {/* <p><span className='left'><strong>Name: </strong>{result && result.first_name} </span>&nbsp;&nbsp;<span className='right'><strong>Username: </strong>{result &&  result.username}</span></p>*/}

//               <td>{result.first_name}</td>
//               <td> {result.username}</td>

//               </tr>

//             ))}

//           </tbody>
//         </table>
//         ) : (
//           <p>No users found.</p>
//         )}
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ResultsModal;

// src/components/ResultsModal.js
import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import loader from "../assets/images/loader.gif";
import useAxios from "../hooks/useAxios";
import "../assets/css/Element_permissions.css";
import "../assets/css/ResultModal.css";
import inactiveUser from '../assets/images/inactiveuser.png';
import activeUser from '../assets/images/activeuser.png';

const ResultsModal = ({ show, onHide, results }) => {
  // State to store the selected filter option
  const [loading, sendRequest] = useAxios();
  const [filter, setFilter] = useState("all");
  const [functionalityId, setFunctionalityId] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [Users, setUsers] = useState([]);
  const [usersCopy, setUsersCopy] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [modalInfo, setModalInfo] = useState({});
  const [isSpin, setisSpin] = useState(false);
  const [isAlerted, setIsAlerted] = useState("0");

  // State to store the enabled/disabled status of each user (by index)
  const [enabledUsers, setEnabledUsers] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEnableAllFunctionalityPop, setShowEnableAllFunctionalityPop] = useState(false);
  const [selectedEndTimeStamp, setSelectedEndTimeStamp] = useState("");
  const [selectedStartTimeStamp, setSelectedStartTimeStamp] = useState("");
  const [selectedCommonEndTimeStamp, setSelectedCommonEndTimeStamp] = useState("");
  const [selectedCommonStartTimeStamp, setSelectedCommonStartTimeStamp] = useState("");
  const [toBeEnabledUsers, setToBeEnabledUsers] = useState({});
  const [toBeDisabledUsers, setToBeDisabledUsers] = useState({});
  const [userData, setUserData] = useState({});
  const [apiMessage, setApiMessage] = useState("");
  const [userStatusId, setUserStatusId] = useState("");
  const [showInputDialog, setShowInputDialog] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userDates, setUserDates] = useState({});




  let alertTriggered = false;
  const users = results.users || [];
  const fId = results.functionality_id || "";
  const currentTimestamp = new Date().getTime();
  console.log("fid" + fId);
  const [divKey, setDivKey] = useState(0);

  const reloadDiv = () => {
    setDivKey((prevKey) => prevKey + 1);
  };

  const addUserDates = (newUser) => {
    setUserDates((prevUsers) => ({
      ...prevUsers,
      [newUser.userid]: newUser,
    }));
  };
  
  // Extract user IDs from the object

  // State to hold the selected user IDs
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // useEffect(() => {
  //   if (Object.keys(userData).length === 0) {
  //     setSelectAll(false);
  //   }
  // }, [userData]);

  useEffect(() => {
    if (userData && Users) { // Ensure both are defined
      if (Object.keys(userData).length !== Object.keys(Users).length) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
    }
  }, [userData, Users]);

  // Handle individual checkbox selection

  const handleCheckboxChange = (userid, status) => {

    if(selectedUsers.includes(userid))
    {

      setSelectedUsers((prevSelected) =>
        prevSelected.includes(userid)
          ? prevSelected.filter((id) => id !== userid) // remove if deselected
          : [...prevSelected, userid] // add if selected
      );
  
  
      setEnabledUsers((prevState) => ({
        ...prevState,
        [userid]: !enabledUsers[userid], // Toggle the current user's status
      }));

      setUserData((prevData) => {
        if (prevData[userid]) {
  
          const newData = { ...prevData };
          delete newData[userid];
          return newData;
        } else {
  
          return {
            ...prevData,
            [userDetails.userid]: userDetails,
          };
        }
      });

      addUserDates({"userid":userid,"start_timestamp":"","end_timestamp":""});
      return;
  
      
    }

    else{

      if (status == true)
      {

      if(userid && userDates[userid] && userDates[userid].end_timestamp=="")
        {
          
          alert("Please enter the dates correctly");
          return;

        }

      }

      else if (status == false) {

        if(userid && userDates[userid] && (userDates[userid].end_timestamp=="" || userDates[userid].start_timestamp==""))
        {
          alert("Please enter the dates correctly");
          return;
        
        
        }
      }


         
    }


    if (status == true) {
      if (!selectedEndTimeStamp) {
        alert("Please enter the dates correctly");
        return;
      }

    }

    else if (status == false) {

      if (!selectedEndTimeStamp || !selectedStartTimeStamp) {
        alert("Please enter the dates correctly");
        return;
      }

    }


    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userid)
        ? prevSelected.filter((id) => id !== userid) // remove if deselected
        : [...prevSelected, userid] // add if selected
    );


    setEnabledUsers((prevState) => ({
      ...prevState,
      [userid]: !enabledUsers[userid], // Toggle the current user's status
    }));

    var userDetails = {};



    if (status == true) {
      if (!selectedEndTimeStamp) {
        alert("Please enter the dates correctly");
        return;
      }
      userDetails = {
        userid: userid,
        end_timestamp: formatDateTime(selectedEndTimeStamp),
      };
    } else if (status == false) {

      if (!selectedEndTimeStamp || !selectedStartTimeStamp) {
        alert("Please enter the dates correctly");
        return;
      }

      userDetails = {
        userid: userid,
        start_timestamp: formatDateTime(selectedStartTimeStamp),
        end_timestamp: formatDateTime(selectedEndTimeStamp),
      };

      const startTimestamp = new Date(userDetails.start_timestamp.replace(" ", "T")).getTime();
      const endTimestamp = new Date(userDetails.end_timestamp.replace(" ", "T")).getTime();

      if (startTimestamp > endTimestamp) {
        alert("The start date must occur before the end date");
        fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
        return;
      }

    }
    console.log(userDetails);

    // setUserData((prevData) => ({
    //   ...prevData,
    //   [userDetails.userid]: userDetails,
    // }));

    setUserData((prevData) => {
      if (prevData[userid]) {

        const newData = { ...prevData };
        delete newData[userid];
        return newData;
      } else {

        return {
          ...prevData,
          [userDetails.userid]: userDetails,
        };
      }
    });

    
    setSelectedEndTimeStamp("");
    setSelectedStartTimeStamp("");
    console.log(userData);
    
    
    

  };

  // Handle Select All toggle
  const handleSelectAll = () => {

    if (userStatusId=== "disabled users") {

      if (!selectedCommonEndTimeStamp || !selectedCommonStartTimeStamp)
        {
            alert("Select dates from 'Enable all' dialog box");
            return;
        }

    }

    else if (userStatusId === "enabled users") {

      if (!selectedCommonEndTimeStamp)
        {
            alert("Select dates from 'Disable all' dialog box");
            return;
        }

      }



    if (selectAll) {
      setSelectedUsers([]); // Deselect all
      setUserData({});
      usersCopy &&
      usersCopy.map((result, index) =>

      setUserDates((prevUsers) => ({
        ...prevUsers,
        [result.userid]: {"userid":result.userid,"start_timestamp":"","end_timestamp":""},
      }))
    );
    }

    else {

      usersCopy &&
      usersCopy.map((result, index) =>

      setUserDates((prevUsers) => ({
        ...prevUsers,
        [result.userid]: {"userid":result.userid,"start_timestamp":selectedCommonStartTimeStamp,"end_timestamp":selectedCommonEndTimeStamp},
      }))
    );
      setSelectedUsers(filteredUsers); // Select all user IDs
      console.log(filteredUsers);
    


      if (userStatusId=== "disabled users") {

        console.log("gieiqgef");
        // console.log(selectedEndTimeStamp);
        // console.log(selectedStartTimeStamp);
        console.log(Users);

        if (!selectedCommonEndTimeStamp || !selectedCommonStartTimeStamp)
          {
              alert("Select dates from 'Enable all' dialog box");
              return;
          }

        if (selectedCommonEndTimeStamp && selectedCommonStartTimeStamp) {

          const startTimestamp = new Date(formatDateTime(selectedCommonStartTimeStamp).replace(" ", "T")).getTime();
          const endTimestamp = new Date(formatDateTime(selectedCommonEndTimeStamp).replace(" ", "T")).getTime();


          if (startTimestamp > endTimestamp) {
            alert("The start date must occur before the end date");
            fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
            return;
          }


        }

        const resultArray = Users.map(user => ({
          userid: user.userid,
          start_timestamp: formatDateTime(selectedCommonStartTimeStamp),
          end_timestamp: formatDateTime(selectedCommonEndTimeStamp)
        }));

        console.log(resultArray);

        var userDataList = {};
        resultArray.forEach(userDetail => {
          setUserData((prevData) => ({
            ...prevData,
            [userDetail.userid]: userDetail,
          }));
        });

        resultArray.forEach(userDetails => {
          userDataList[userDetails.userid] = userDetails;
        });

        console.log(userData);

        //enableUserFunctionalityInMass(functionalityId, permissionId, userDataList);

        console.log("heyyyy");



      }

      else if (userStatusId === "enabled users") {

        if (!selectedCommonEndTimeStamp)
          {
              alert("Select dates from 'Disable all' dialog box");
              return;
          }

        const resultArray = Users.map(user => ({
          userid: user.userid,
          end_timestamp: formatDateTime(selectedCommonEndTimeStamp)
        }));


        resultArray.forEach(userDetail => {
          setUserData((prevData) => ({
            ...prevData,
            [userDetail.userid]: userDetail,
          }));
        });

        var userDataList = {};
        resultArray.forEach(userDetails => {
          userDataList[userDetails.userid] = userDetails;
        });

        //disableUserFunctionalityInMass(functionalityId, permissionId, userDataList);


      }

      setUserData(userDataList);

    }
    setSelectAll(!selectAll);
  
  };

  useEffect(() => {

    setUserStatusId("");
    setSelectedUsers([]);
    setSelectAll(false)
    usersCopy &&
    usersCopy.map((result, index) =>

    setUserDates((prevUsers) => ({
      ...prevUsers,
      [result.userid]: {"userid":result.userid,"start_timestamp":"","end_timestamp":""},
    }))
  );

  setSelectedCommonEndTimeStamp("");
  setSelectedCommonStartTimeStamp("");
  setSelectedStartTimeStamp("");
  setSelectedEndTimeStamp("");
  setUserData({});

  }, [permissionId])

  useEffect(() => {

    setSelectedUsers([]);
    setSelectAll(false)
    usersCopy &&
    usersCopy.map((result, index) =>

    setUserDates((prevUsers) => ({
      ...prevUsers,
      [result.userid]: {"userid":result.userid,"start_timestamp":"","end_timestamp":""},
    }))
  );

  setSelectedCommonEndTimeStamp("");
  setSelectedCommonStartTimeStamp("");
  setSelectedStartTimeStamp("");
  setSelectedEndTimeStamp("");
  setUserData({})
    

  }, [userStatusId])


  useEffect(() => {
    setFunctionalityId(fId);
    setUsers(results.users);
    setAllUsers(results.users);
    setUserData({});
    Users &&
      Users.map((result, index) =>
        setEnabledUsers((prevStatus) => ({
          ...prevStatus,
          [result.userid]:
            result.end_timestamp &&
            !currentTimestamp <
            new Date(result.end_timestamp.replace(" ", "T")).getTime(),
        }))
      );

    Users &&
      Users.map((result, index) =>
        setEnabledUsers((prevStatus) => ({
          ...prevStatus,
          [result.userid]:
            result.end_timestamp &&
            !(currentTimestamp <
              new Date(result.end_timestamp.replace(" ", "T")).getTime()),
        }))
      );

    // Users &&
    // Users.map((result, index) =>
    //   setEnabledUsers((prevStatus) => ({
    //     ...prevStatus,
    //     [result.userid]:
    //     (result.start_timestamp &&
    //       result.end_timestamp && currentTimestamp >=
    //       new Date(result.start_timestamp.replace(" ", "T")).getTime() &&
    //       currentTimestamp <
    //       new Date(result.end_timestamp.replace(" ", "T")).getTime())? (result.start_timestamp &&
    //         result.end_timestamp && currentTimestamp <
    //         new Date(result.start_timestamp.replace(" ", "T")).getTime() ||
    //         currentTimestamp >=
    //         new Date(result.end_timestamp.replace(" ", "T")).getTime()): (result.start_timestamp &&
    //         result.end_timestamp && currentTimestamp >=
    //         new Date(result.start_timestamp.replace(" ", "T")).getTime() &&
    //         currentTimestamp <
    //         new Date(result.end_timestamp.replace(" ", "T")).getTime())
    //   }))
    // );

    console.log(enabledUsers);
    const fetchPermissionsForFunctionality = (functionalityId) => {
      try {
        setisSpin(true);

        const form = new FormData();

        form.append("flag", "get_permission_by_functionality");
        form.append("functionality_id", functionalityId);

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);

        sendRequest(
          {
            url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
            method: "POST",
            data: form,
            headers: {
              // 'Content-Type': 'application/json',
              "api-token": apitoken,
            },
          },
          (response) => {
            console.log(response);
            setisSpin(false);
            setPermissions(response.permissions);

            return response;
          }
        );
      } catch (error) {
        setisSpin(false);
        console.error("Error updating permissions:", error);
      }
    };

    setPermissions([]);
    setPermissionId("");

    if (fId) {
      fetchPermissionsForFunctionality(fId);
    }

    setShowConfirmationModal(false);
    setSelectedEndTimeStamp('');
    setSelectedStartTimeStamp('');
    setIsAlerted("0");
    setSelectedUsers([]);
    setSelectAll(false)
  }, [show]);

  const disableUserFunctionality = (functionalityId, permissionId, userDataList = {}) => {
    console.log(userData);
    var userDataObject = Object.keys(userData).length > 0 ? userData : userDataList;
    console.log(userDataObject);
    var isValid = true;
    Object.keys(userDataObject).forEach((key) => {
      if (!("start_timestamp" in userDataObject[key])) {
        if (userDataObject[key].end_timestamp == null) {
          isValid = false;
        }
      }
    });

    const userArray = [];

    Object.keys(userDataObject).forEach((key) => {
      if (!("start_timestamp" in userDataObject[key])) {
        userArray.push(userDataObject[key]);
      }
    });

    if (isValid && userArray.length > 0) {
      try {
        setisSpin(true);
        const form = new FormData();

        form.append("flag", "disable_functionality_for_users");
        form.append("functionality_id", functionalityId);
        form.append("permission_id", permissionId);

        console.log(userArray);

        setShowConfirmationModal(true);

        form.append("users", JSON.stringify(JSON.stringify(userArray)));

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);
        setPermissionId(permissionId);
        setModalInfo({
          title: "Functionality Status",
          message: "Functionality has been updated for all the selected users",
        });
        console.log("Hello");
        //setShowConfirmationModal(true);

        isValid &&
          userArray.length > 0 &&
          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
              method: "POST",
              data: form,
              headers: {
                // 'Content-Type': 'application/json',
                "api-token": apitoken,
              },
            },

            (response) => {
              setisSpin(false);

              setApiMessage(
                "Functionality has been updated for all the selected users"
              );
              console.log(response);
              fetchUsersForFunctionalityAndPermission(
                functionalityId,
                permissionId
              );
            },

            (error) => {
              //setShowConfirmationModal(true);

              // setApiMessage(error);
              console.log("Error in API request", error);
            }
          );
      } catch (error) {
        setApiMessage(error);

        setisSpin(false);

        console.log("Error updating permissions:", error);
      }
    } else if (isValid == false) {
      // if (isAlerted === "0") {
      //   alert("Please enter the dates correctly");
      //   setIsAlerted("1");
      // }

      if (alertTriggered == false) {
        alert("Please enter the dates correctly");
        alertTriggered = true;
      }

      console.log(isAlerted);
      fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      setShowConfirmationModal(false);
      //showInputDialog(false);
      console.log(isAlerted);

    }
  };

  const disableUserFunctionalityInMass = (functionalityId, permissionId, userDataList = {}) => {
    console.log(userData);
    var userDataObject = userDataList;
    console.log(userDataObject);
    var isValid = true;
    Object.keys(userDataObject).forEach((key) => {
      if (!("start_timestamp" in userDataObject[key])) {
        if (userDataObject[key].end_timestamp == null) {
          isValid = false;
        }
      }
    });

    const userArray = [];

    Object.keys(userDataObject).forEach((key) => {
      if (!("start_timestamp" in userDataObject[key])) {
        userArray.push(userDataObject[key]);
      }
    });

    if (isValid && userArray.length > 0) {
      try {
        setisSpin(true);
        const form = new FormData();

        form.append("flag", "disable_functionality_for_users");
        form.append("functionality_id", functionalityId);
        form.append("permission_id", permissionId);

        console.log(userArray);

        setShowConfirmationModal(true);

        form.append("users", JSON.stringify(JSON.stringify(userArray)));

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);
        setPermissionId(permissionId);
        setModalInfo({
          title: "Functionality Status",
          message: "Functionality has been updated for all the selected users",
        });
        console.log("Hello");
        //setShowConfirmationModal(true);

        isValid &&
          userArray.length > 0 &&
          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
              method: "POST",
              data: form,
              headers: {
                // 'Content-Type': 'application/json',
                "api-token": apitoken,
              },
            },

            (response) => {
              setisSpin(false);

              setApiMessage(
                "Functionality has been updated for all the selected users"
              );
              console.log(response);
              fetchUsersForFunctionalityAndPermission(
                functionalityId,
                permissionId
              );
            },

            (error) => {
              //setShowConfirmationModal(true);

              // setApiMessage(error);
              console.log("Error in API request", error);
            }
          );
      } catch (error) {
        setApiMessage(error);

        setisSpin(false);

        console.log("Error updating permissions:", error);
      }
    } else if (isValid == false) {
      // if (isAlerted === "0") {
      //   alert("Please enter the dates correctly");
      //   setIsAlerted("1");
      // }

      if (alertTriggered == false) {
        alert("Please enter the dates correctly");
        alertTriggered = true;
      }

      console.log(isAlerted);
      fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      setShowConfirmationModal(false);
      //showInputDialog(false);
      console.log(isAlerted);

    }
  };

  const enableUserFunctionalityInMass = (functionalityId, permissionId, userDataList = {}) => {
    console.log(userDataList);

    var isValid = true;

    var userDataObject = userDataList;

    console.log(userDataObject);


    Object.keys(userDataObject).forEach((key) => {
      if ("start_timestamp" in userDataObject[key]) {
        if (
          userDataObject[key].start_timestamp == null ||
          userDataObject[key].end_timestamp == null
        ) {
          isValid = false;
        }
      }
    });





    const userArray = [];

    Object.keys(userDataObject).forEach((key) => {
      if ("start_timestamp" in userDataObject[key]) {
        userArray.push(userDataObject[key]);
      }
    });

    if (isValid && userArray.length > 0) {
      try {
        setisSpin(true);
        const form = new FormData();

        form.append("flag", "enable_functionality_for_users");
        form.append("functionality_id", functionalityId);
        form.append("permission_id", permissionId);

        console.log(userArray);

        setShowConfirmationModal(true);

        form.append("users", JSON.stringify(JSON.stringify(userArray)));

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);
        setPermissionId(permissionId);
        setModalInfo({
          title: "Functionality Status",
          message: "Functionality has been updated for all the selected users",
        });

        //setShowConfirmationModal(true);
        console.log("Hello");

        isValid &&
          userArray.length > 0 &&
          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
              method: "POST",
              data: form,
              headers: {
                // 'Content-Type': 'application/json',
                "api-token": apitoken,
              },
            },

            (response) => {
              setisSpin(false);

              setApiMessage(
                "Functionality has been updated for all the selected users"
              );
              console.log(response);
              fetchUsersForFunctionalityAndPermission(
                functionalityId,
                permissionId
              );
            },

            (error) => {
              //setShowConfirmationModal(true);

              // setApiMessage(error);
              console.log("Error in API request", error);
            }
          );
      } catch (error) {
        setApiMessage(error);

        setisSpin(false);

        console.log("Error updating permissions:", error);
      }
    } else if (isValid == false) {
      console.log(showConfirmationModal);
      setShowConfirmationModal(false);
      fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      console.log(showConfirmationModal);
      setShowConfirmationModal(false);
      //showInputDialog(false);
      console.log(isAlerted);

      // if (isAlerted === "1") 
      // {
      //      return;
      // }

      // if (isAlerted === "0") {
      //   alert("Please enter the dates correctly");
      //   setIsAlerted("1");

      // }

      if (alertTriggered == false) {
        alert("Please enter the dates correctly");
        alertTriggered = true;
      }



      setShowConfirmationModal(false);
      //showInputDialog(false);
    }
  };


  const enableUserFunctionality = (functionalityId, permissionId, userDataList = {}) => {
    console.log(userDataList);

    var isValid = true;

    var userDataObject = Object.keys(userData).length > 0 ? userData : userDataList;

    console.log(userDataObject);


    Object.keys(userDataObject).forEach((key) => {
      if ("start_timestamp" in userDataObject[key]) {
        if (
          userDataObject[key].start_timestamp == null ||
          userDataObject[key].end_timestamp == null
        ) {
          isValid = false;
        }
      }
    });





    const userArray = [];

    Object.keys(userDataObject).forEach((key) => {
      if ("start_timestamp" in userDataObject[key]) {
        userArray.push(userDataObject[key]);
      }
    });

    if (isValid && userArray.length > 0) {
      try {
        setisSpin(true);
        const form = new FormData();

        form.append("flag", "enable_functionality_for_users");
        form.append("functionality_id", functionalityId);
        form.append("permission_id", permissionId);

        console.log(userArray);

        setShowConfirmationModal(true);

        form.append("users", JSON.stringify(JSON.stringify(userArray)));

        let apitoken = localStorage.getItem("api_token");

        setFunctionalityId(functionalityId);
        setPermissionId(permissionId);
        setModalInfo({
          title: "Functionality Status",
          message: "Functionality has been updated for all the selected users",
        });

        //setShowConfirmationModal(true);
        console.log("Hello");

        isValid &&
          userArray.length > 0 &&
          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
              method: "POST",
              data: form,
              headers: {
                // 'Content-Type': 'application/json',
                "api-token": apitoken,
              },
            },

            (response) => {
              setisSpin(false);

              setApiMessage(
                "Functionality has been updated for all the selected users"
              );
              console.log(response);
              fetchUsersForFunctionalityAndPermission(
                functionalityId,
                permissionId
              );
            },

            (error) => {
              //setShowConfirmationModal(true);

              // setApiMessage(error);
              console.log("Error in API request", error);
            }
          );
      } catch (error) {
        setApiMessage(error);

        setisSpin(false);

        console.log("Error updating permissions:", error);
      }
    } else if (isValid == false) {
      console.log(showConfirmationModal);
      setShowConfirmationModal(false);
      fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      console.log(showConfirmationModal);
      setShowConfirmationModal(false);
      //showInputDialog(false);
      console.log(isAlerted);

      // if (isAlerted === "1") 
      // {
      //      return;
      // }

      // if (isAlerted === "0") {
      //   alert("Please enter the dates correctly");
      //   setIsAlerted("1");

      // }

      if (alertTriggered == false) {
        alert("Please enter the dates correctly");
        alertTriggered = true;
      }



      setShowConfirmationModal(false);
      //showInputDialog(false);
    }
  };

  // const enableUserFunctionality = async (functionalityId, permissionId) => {

  //   var isValid=true;
  //   Object.keys(userData).forEach(key => {

  //     if (('start_timestamp' in userData[key])) {
  //       console.log("heeeeey"+userData[key].start_timestamp);

  //       if(userData[key].start_timestamp==null || userData[key].end_timestamp==null)
  //       {

  //           isValid=false;
  //       }

  //     }
  //   });

  //   if(isValid)
  //   {

  //    try
  //   {

  //     setisSpin(true);

  //     const form = new FormData();

  //     form.append("flag", "enable_functionality_for_users")
  //     form.append("functionality_id", functionalityId)
  //     form.append("permission_id", permissionId)
  //     const userArray = []

  //     Object.keys(userData).forEach(key => {

  //       if (('start_timestamp' in userData[key])) {
  //         userArray.push(userData[key]);

  //       }
  //     });

  //     console.log(userArray);
  //     setShowConfirmationModal(true);
  //     var temp = {};

  //     form.append("users", JSON.stringify(JSON.stringify(userArray)))

  //     let apitoken = localStorage.getItem("api_token");

  //     setFunctionalityId(functionalityId);
  //     setPermissionId(permissionId);

  //     setModalInfo({
  //       title: 'Functionality Status',
  //       message: "Functionality has been updated for all the selected users",

  //     });

  //     isValid && userArray.length > 0 && sendRequest(
  //       {
  //         url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
  //         method: "POST",
  //         data: form,
  //         headers: {

  //          // 'Content-Type': 'application/json',
  //           "api-token": apitoken,
  //         },

  //       },
  //       (response) => {
  //         temp=response;

  //         setApiMessage("Functionality has been updated for all the selected users");
  //         //setShowConfirmationModal(true);
  //         setisSpin(false);
  //         fetchUsersForFunctionalityAndPermission(functionalityId,permissionId);
  //         console.log(response);

  //         return response
  //       },

  //       // (error) => {

  //       //   //setShowConfirmationModal(true);
  //       //   setApiMessage(error);
  //       //   console.error("Error in API request", error);
  //       // }

  //       (error) => {
  //         //setShowConfirmationModal(true);
  //         //setApiMessage("Error in updating the functionality");
  //         console.log("Error in API request", error);

  //       }

  //     );

  //     //setisSpin(false);

  //   }

  //   catch (error) {

  //     //setShowConfirmationModal(true);
  //     //setApiMessage("Error in updating the functionality");
  //     console.log("Error in API request", error);

  //   }

  // }

  // else
  // {
  //   fetchUsersForFunctionalityAndPermission(functionalityId,permissionId);
  //   setShowConfirmationModal(false);
  //   alert("Please enter the dates correctly");

  // }

  // };

  const handleFunctionalityUpdateButton = () => {
    disableUserFunctionality(functionalityId, permissionId);
    console.log(isAlerted);
    enableUserFunctionality(functionalityId, permissionId);
    setUserStatusId("");

    setisSpin(false);
    // Users &&
    //   Users.map((result, index) =>
    //     setEnabledUsers((prevStatus) => ({
    //       ...prevStatus,
    //       [result.userid]:
    //       (result.start_timestamp &&
    //         result.end_timestamp && currentTimestamp >=
    //         new Date(result.start_timestamp.replace(" ", "T")).getTime() &&
    //         currentTimestamp <
    //         new Date(result.end_timestamp.replace(" ", "T")).getTime())? (result.start_timestamp &&
    //           result.end_timestamp && currentTimestamp <
    //           new Date(result.start_timestamp.replace(" ", "T")).getTime() ||
    //           currentTimestamp >=
    //           new Date(result.end_timestamp.replace(" ", "T")).getTime()): (result.start_timestamp &&
    //           result.end_timestamp && currentTimestamp >=
    //           new Date(result.start_timestamp.replace(" ", "T")).getTime() &&
    //           currentTimestamp <
    //           new Date(result.end_timestamp.replace(" ", "T")).getTime())
    //     }))
    //   );



    Users &&
      Users.map((result, index) =>
        setEnabledUsers((prevStatus) => ({
          ...prevStatus,
          [result.userid]:
            result.end_timestamp &&
            !(currentTimestamp <
              new Date(result.end_timestamp.replace(" ", "T")).getTime()),
        }))
      );

    reloadDiv();

    setUserData({});
    console.log(modalInfo);
    setApiMessage("");


    fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
    alertTriggered = false;
    setIsAlerted("0");
    setSelectedUsers([]);
    setSelectAll(!selectAll);
    setSelectAll(false);
    //setShowConfirmationModal(false);
  };

  const fetchUsersForFunctionalityAndPermission = (
    functionalityId,
    permissionId
  ) => {
    try {
      setisSpin(true);

      const form = new FormData();

      form.append("flag", "get_users_by_permission_and_functionality");
      form.append("functionality_id", functionalityId);
      form.append("permission_id", permissionId);

      console.log(permissionId);
      console.log(functionalityId);

      console.log(form);

      let apitoken = localStorage.getItem("api_token");

      setFunctionalityId(functionalityId);
      setPermissionId(permissionId);

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data: form,
          headers: {
            // 'Content-Type': 'application/json',
            "api-token": apitoken,
          },
        },
        (response) => {
          setisSpin(false);
          console.log(response);
          setUsers(response.users);
          setUsersCopy(response.users);

          Users &&
            Users.map((result, index) =>
              setEnabledUsers((prevStatus) => ({
                ...prevStatus,
                [result.userid]:
                  result.end_timestamp && result.start_timestamp &&
                  currentTimestamp <
                  new Date(result.end_timestamp.replace(" ", "T")).getTime() && currentTimestamp >=
                  new Date(result.start_timestamp.replace(" ", "T")).getTime(),
              }))
            );

            
            response.users &&
            response.users.map((result, index) =>

            setUserDates((prevUsers) => ({
              ...prevUsers,
              [result.userid]: {"userid":result.userid,"start_timestamp":"","end_timestamp":""},
            }))
          );

          // Users &&
          // Users.map((result, index) =>
          //   setEnabledUsers((prevStatus) => ({
          //     ...prevStatus,
          //     [result.userid]:
          //     (result.start_timestamp &&
          //       result.end_timestamp && currentTimestamp >=
          //       new Date(result.start_timestamp.replace(" ", "T")).getTime() &&
          //       currentTimestamp <
          //       new Date(result.end_timestamp.replace(" ", "T")).getTime())? (result.start_timestamp &&
          //         result.end_timestamp && currentTimestamp <
          //         new Date(result.start_timestamp.replace(" ", "T")).getTime() ||
          //         currentTimestamp >=
          //         new Date(result.end_timestamp.replace(" ", "T")).getTime()): (result.start_timestamp &&
          //         result.end_timestamp && currentTimestamp >=
          //         new Date(result.start_timestamp.replace(" ", "T")).getTime() &&
          //         currentTimestamp <
          //         new Date(result.end_timestamp.replace(" ", "T")).getTime())
          //   }))
          // );

          return response;
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error("Error updating permissions:", error);
      //alert('Failed to update permissions.');
    }
  };

  // Handle dropdown change
  const handlePermissionChange = (e) => {
    const filterValue = e.target.value;
    setPermissionId(filterValue);
    console.log("fun" + fId);
    console.log("perm" + filterValue);

    if (filterValue) {
      fetchUsersForFunctionalityAndPermission(fId, filterValue);
    } else {
      setUsers(allUsers);
    }
  };

  const handleEnableDisableButtonClick = () => {
    setShowInputDialog(true);
  }

  const handleUserStatusChange = (e) => {
    const filterValue = e.target.value;
    setUserStatusId(filterValue);
    var filteredData = []
    console.log("userStatus : " + filterValue);


    if (filterValue == "enabled users") {
      filteredData = usersCopy && usersCopy.filter(result => {
        const endTimestamp = new Date(result.end_timestamp.replace(" ", "T")).getTime(); // Convert end_timestamp to milliseconds
        const startTimestamp = new Date(result.start_timestamp.replace(" ", "T")).getTime();
        return currentTimestamp < endTimestamp && currentTimestamp >= startTimestamp; // Compare with current timestamp
      });
      console.log(filteredData);
      const userIds = Object.values(filteredData).map(user => user.userid);
      console.log(userIds);
      setFilteredUsers(userIds);

    }

    else if (filterValue == "disabled users") {
      filteredData = usersCopy && usersCopy.filter(result => {
        const startTimestamp = new Date(result.start_timestamp.replace(" ", "T")).getTime();
        const endTimestamp = new Date(result.end_timestamp.replace(" ", "T")).getTime(); // Convert end_timestamp to milliseconds
        return currentTimestamp < startTimestamp || currentTimestamp >= endTimestamp; // Compare with current timestamp
      });

      console.log(filteredData);
      const userIds = Object.values(filteredData).map(user => user.userid);
      console.log(userIds);
      setFilteredUsers(userIds);
    }



    if (filterValue) {
      setUsers(filteredData);
    }
    else {
      setUsers(usersCopy);
    }
  };


  // Handle checkbox toggle for enabling/disabling functionality
  const handleCheckboxToggle = (userid, status) => {
    console.log(status);
    console.log(!status);

    setEnabledUsers((prevState) => ({
      ...prevState,
      [userid]: !enabledUsers[userid], // Toggle the current user's status
    }));

    var userDetails = {};

    if (status == true) {
      userDetails = {
        userid: userid,
        end_timestamp: formatDateTime(selectedEndTimeStamp),
      };
    } else if (status == false) {
      userDetails = {
        userid: userid,
        start_timestamp: formatDateTime(selectedStartTimeStamp),
        end_timestamp: formatDateTime(selectedEndTimeStamp),
      };

      const startTimestamp = new Date(userDetails.start_timestamp.replace(" ", "T")).getTime();
      const endTimestamp = new Date(userDetails.end_timestamp.replace(" ", "T")).getTime();

      if (startTimestamp > endTimestamp) {
        alert("The start date must occur before the end date");
        fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
        return;
      }

    }
    console.log(userDetails);

    setUserData((prevData) => ({
      ...prevData,
      [userDetails.userid]: userDetails,
    }));

    //setSelectedStartTimeStamp('');
    // setSelectedEndTimeStamp('');
  };

  const handleCommonEndDatetimeChange=(event)=>
  {
    setSelectedCommonEndTimeStamp(event.target.value);
  }

  const handleCommonStartDatetimeChange=(event)=>
    {
      setSelectedCommonStartTimeStamp(event.target.value);
    }

  const handleEndDatetimeChange = (event,userid) => {
    setSelectedEndTimeStamp(event.target.value);
    const endDate=event.target.value;
    console.log(event.target.value);
    addUserDates({"userid":userid,"start_timestamp":userid && userDates[userid]? userDates[userid].start_timestamp:"","end_timestamp":endDate})
    //addUserDates({"userid":userid,"start_timestamp":userid && userDates[userid]? userDates[userid].start_timestamp:"","end_timestamp":selectedEndTimeStamp})
    // reloadDiv()
  };

  const handleStartDatetimeChange = (event,userid) => {
    setSelectedStartTimeStamp(event.target.value);
    const startDate=event.target.value;
    console.log(event.target.value);
    addUserDates({"userid":userid,"start_timestamp":startDate,"end_timestamp":userid && userDates[userid]?userDates[userid].end_timestamp:""})
    // addUserDates({"userid":userid,"start_timestamp":startDate,"end_timestamp":userid && userDates[userid]?userDates[userid].end_timestamp:""})
    // // reloadDiv()
  };

  const handleModalCancel = () => {
    setShowConfirmationModal(false);
    setApiMessage("");
    setSelectedStartTimeStamp("");
    setSelectedEndTimeStamp("");
    fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
  };


  const handleModalCancelSaveDates = () => {
    setShowInputDialog(false);
    setShowConfirmationModal(false);
    setApiMessage("");
    setSelectedCommonEndTimeStamp("");
    setSelectedCommonStartTimeStamp("");
    setSelectedStartTimeStamp("");
    setSelectedEndTimeStamp("");
  };

  const handleInputModalCancel = () => {
    setShowInputDialog(false);

  };

  const handleMassUpdateButton = (uid) => {


    if (userStatusId) {

      setisSpin(true);
      console.log(userStatusId);

      if (uid === "disabled users") {

        console.log("gieiqgef");
        console.log(selectedEndTimeStamp);
        console.log(selectedStartTimeStamp);
        console.log(Users);

        if (selectedEndTimeStamp && selectedStartTimeStamp) {

          const startTimestamp = new Date(formatDateTime(selectedStartTimeStamp).replace(" ", "T")).getTime();
          const endTimestamp = new Date(formatDateTime(selectedEndTimeStamp).replace(" ", "T")).getTime();


          if (startTimestamp > endTimestamp) {
            alert("The start date must occur before the end date");
            fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
            return;
          }


        }

        const resultArray = Users.map(user => ({
          userid: user.userid,
          start_timestamp: formatDateTime(selectedStartTimeStamp),
          end_timestamp: formatDateTime(selectedEndTimeStamp)
        }));

        console.log(resultArray);

        var userDataList = {};
        resultArray.forEach(userDetail => {
          setUserData((prevData) => ({
            ...prevData,
            [userDetail.userid]: userDetail,
          }));
        });

        resultArray.forEach(userDetails => {
          userDataList[userDetails.userid] = userDetails;
        });

        console.log(userData);

        enableUserFunctionalityInMass(functionalityId, permissionId, userDataList);

        console.log("heyyyy");



      }

      else if (uid === "enabled users") {

        const resultArray = Users.map(user => ({
          userid: user.userid,
          end_timestamp: formatDateTime(selectedEndTimeStamp)
        }));


        resultArray.forEach(userDetail => {
          setUserData((prevData) => ({
            ...prevData,
            [userDetail.userid]: userDetail,
          }));
        });

        var userDataList = {};
        resultArray.forEach(userDetails => {
          userDataList[userDetails.userid] = userDetails;
        });

        disableUserFunctionalityInMass(functionalityId, permissionId, userDataList);


      }

      setisSpin(false);



      // setUserData((prevData) => ({
      //   ...prevData,
      //   [userDetails.userid]: userDetails,
      // }));

      // setSelectedStartTimeStamp('');
      // setSelectedEndTimeStamp('');

      fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      setUserStatusId("");
      setUsers(usersCopy);
      setShowInputDialog(false);
      setSelectedUsers([]);

    }

    else {
      // setisSpin(true);
    }

    setUserData({});


  };


  const handleSaveDatesButton = (uid) => {


    if (userStatusId) {

      setisSpin(true);
      console.log(userStatusId);

      if (uid === "disabled users") {

        console.log("gieiqgef");
        console.log(selectedEndTimeStamp);
        console.log(selectedStartTimeStamp);
        console.log(Users);

        if (!selectedCommonEndTimeStamp || !selectedCommonStartTimeStamp) 
        {
          alert("Select start and end dates");
          setisSpin(false);
          return;
        }

        if (selectedCommonEndTimeStamp && selectedCommonStartTimeStamp) {

          const startTimestamp = new Date(formatDateTime(selectedCommonStartTimeStamp).replace(" ", "T")).getTime();
          const endTimestamp = new Date(formatDateTime(selectedCommonEndTimeStamp).replace(" ", "T")).getTime();


          if (startTimestamp > endTimestamp) {
            alert("The start date must occur before the end date");
            setisSpin(false);
            // fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
            return;
          }


        }

      }

      else if (uid === "enabled users") {

        if (!selectedCommonEndTimeStamp) 
          {
            alert("Select end date");
            setisSpin(false);
            return;
          }

      }

      setisSpin(false);



      // setUserData((prevData) => ({
      //   ...prevData,
      //   [userDetails.userid]: userDetails,
      // }));

      // setSelectedStartTimeStamp('');
      // setSelectedEndTimeStamp('');

      //fetchUsersForFunctionalityAndPermission(functionalityId, permissionId);
      //setUserStatusId("");
      //setUsers(usersCopy);
      setShowInputDialog(false);
      // setSelectedUsers([]);

    }

    else {
      // setisSpin(true);
    }

    // setUserData({});


  };




  const formatDateTime = (datetimeString) => {
    if (datetimeString == null || datetimeString === "") {
      return null;
    }

    const date = new Date(datetimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const formatDate = (dateTimeString) => {
    if (dateTimeString == null || dateTimeString === "") {
      return null;
    }

    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // const updateUser = (newUser) => {
  //   setUserData((prevData) => ({
  //     ...prevData,
  //     [newUser.userid]: newUser
  //   }));
  // };

  // Filter results based on the dropdown selection
  // const filteredResults = results.filter((result) => {
  //   if (filter === 'all') return true;
  //   return result.username.startsWith(filter); // Example: Filter by usernames starting with the selected filter
  // });

  return (
    <>
      {isSpin ? (
        <div className="preloader">
          <img className="w30" src={loader} />
        </div>
      ) : (
        ""
      )}
      <br />
      <br />

      <Modal show={showConfirmationModal} onHide={handleModalCancel}>
        <Modal.Header>
          <Modal.Title>Functionality Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {apiMessage === "" ? (
            <p>Loading please wait ...</p>
          ) : (
            <p>{apiMessage}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalCancel}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showInputDialog} onHide={handleModalCancelSaveDates}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "18px" }}>{userStatusId === "enabled users" ? "Enter the end date to disable all the users under this permission" : "Enter the start and end dates to enable all the users under this permission"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputDialog">
            <p title="  Enter new start date" hidden={userStatusId === "enabled users"}>
              <strong>Start date : </strong>
            </p>

            <input id="dateInput" className="dialogInput"
              type="datetime-local"      
              title={
                ""

              }
              onChange={handleCommonStartDatetimeChange}
              style={{ width: "80px" }} hidden={userStatusId === "enabled users"}></input>

            &nbsp;&nbsp;&nbsp;

            <p title="Enter new end date">
              <strong>End date : </strong>
            </p>
            <input id="dateInput" className="dialogInput"
              type="datetime-local"
              title={
                ""

              }
              onChange={handleCommonEndDatetimeChange}
              style={{ width: "80px" }}></input>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCancelSaveDates}>
            Cancel
          </Button>

          &nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;

          <Button variant="secondary" onClick={() => handleSaveDatesButton(userStatusId)}>
            Save
          </Button>

          {/* <Button variant="secondary" onClick={() => handleMassUpdateButton(userStatusId)}>
            {userStatusId === "enabled users" ? "Disable all" : "Enable all"}
          </Button> */}
        </Modal.Footer>
      </Modal>


      <Modal
        show={show}
        onHide={onHide}
        centered
        //style={{ width: "1200px" }}
        className=".modal-dialog"
      >
        <Modal.Header >
          <Modal.Title>Users</Modal.Title>
          <Button hidden={!userStatusId} disabled={Users && userStatusId && Users.length == 0} variant="secondary" onClick={handleEnableDisableButtonClick}>{userStatusId === "enabled users" ? "Disable all" : "Enable all"}</Button>



        </Modal.Header>
        <Modal.Body>
          {
            //console(results["functionality_id"])
          }
          {/* Dropdown for filtering
        <Form.Group controlId="filterDropdown">
          <Form.Label>Select Permission</Form.Label>
          <Form.Control as="select" value={"Select permission"} onChange={handlePermissionChange}>
            {permissions && permissions.length>0 &&  permissions.map((permission) => (
          <option key={permission.permission_id} value={permission.permission_id}>
            {permission.permission_name}
          </option>
        ))}
          </Form.Control>
        </Form.Group> */}

          <div className={permissionId ? 'dropdownMenu' : ''}>

            <div className="dropdown">
              <label htmlFor="app">Permissions : </label>
              <select
                id="permissions"
                value={permissionId}
                onChange={handlePermissionChange}
              >
                <option value="">select permission</option>

                {permissions &&
                  permissions.length > 0 &&
                  permissions.map((permission) => (
                    <option
                      key={permission.permission_id}
                      value={permission.permission_id}
                    >
                      {permission.permission_name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="dropdown">
              <label htmlFor="app" hidden={!permissionId}>User status :</label>
              <select
                id="permissions"
                value={userStatusId}
                onChange={handleUserStatusChange}
                hidden={!permissionId}
              >
                <option value="">select user status</option>


                <option
                  key="enabled users"
                  value="enabled users"
                >
                  enabled users
                </option>

                <option
                  key="disabled users"
                  value="disabled users"
                >
                  disabled users
                </option>

              </select>
            </div>
          </div>
          <div className="note" hidden={!permissionId || userStatusId}>
            <p>* Please select 'user status' to update the users' permissions.</p>
          </div>

          {Users && Users.length > 0 ? (
            <table className="results-table" width="100%" key={divKey}>
              <thead>
                <tr key={divKey}>
                  <th>Name</th>
                  <th>Username</th>

                  <th hidden={!permissionId}>Start date</th>
                  <th hidden={!permissionId}>End date</th>
                  <th hidden={!permissionId}>
  {!userStatusId ? (
    "Status"
  ) : (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      Action (check all)
      <input
        hidden={!userStatusId}
        // disabled={userStatusId === "enabled users" ? (!selectedEndTimeStamp) :(!selectedEndTimeStamp && !selectedStartTimeStamp)}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}

        title={"select dates at first"}
         // Optional: Adds space between "Action" and the checkbox
      />
    </span>
  )}
</th>
                </tr>
              </thead>
              <tbody>
                {
                  Users.map((result, index) => (
                    <tr
                      key={index}
                      title={result.start_timestamp &&
                        result.end_timestamp && currentTimestamp >=
                        new Date(
                          result.start_timestamp.replace(" ", "T")
                        ).getTime() &&
                        currentTimestamp <
                        new Date(
                          result.end_timestamp.replace(" ", "T")
                        ).getTime()
                        ? "Functionality is enabled for this user"
                        : "Functionality is disabled for this user"
                      }

                      className={result.start_timestamp &&
                        result.end_timestamp && currentTimestamp >=
                        new Date(
                          result.start_timestamp.replace(" ", "T")
                        ).getTime() &&
                        currentTimestamp <
                        new Date(
                          result.end_timestamp.replace(" ", "T")
                        ).getTime()
                        ? "active-row"
                        : "default-row"
                      }
                    >
                      {console.log(enabledUsers)}
                      {/* 
                  { setEnabledUsers((prevStatus) => ({
                   ...prevStatus, 
                 [result.userid]: result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime(), 
                  }))}
                   */}
                      <td>{result.first_name}</td>
                      <td>{result.username}</td>

                      <td hidden={!permissionId}>
                        <p title="current start date">
                          <u>{formatDate(result.start_timestamp)}</u>
                        </p>
                        <input
                       // value={selectAll?selectedStartTimeStamp:null}
                        //value={selectAll?selectedStartTimeStamp:(result.userid && userDates[result.userid]?userDates[result.userid].start_timestamp:"")}
                        value={(result.userid && userDates[result.userid]?userDates[result.userid].start_timestamp:"")}
                          hidden={(
                            result.end_timestamp && result.start_timestamp && currentTimestamp >=
                            new Date(
                              result.start_timestamp.replace(" ", "T")
                            ).getTime() &&
                            currentTimestamp <
                            new Date(
                              result.end_timestamp.replace(" ", "T")
                            ).getTime()) || !permissionId || !userStatusId
                          }
                          type="datetime-local"
                          title="Enter the start date to enable"
                          onChange={(e)=>handleStartDatetimeChange(e,result.userid)}
                        ></input>
                      </td>

                      <td hidden={!permissionId}>
                        <p title="current end date">
                          <u>{formatDate(result.end_timestamp)}</u>
                        </p>
                        <input hidden={!permissionId || !userStatusId}
                          type="datetime-local"
                          title={
                            result.start_timestamp && currentTimestamp >=
                              new Date(
                                result.start_timestamp.replace(" ", "T")
                              ).getTime() &&
                              result.end_timestamp &&
                              currentTimestamp <
                              new Date(
                                result.end_timestamp.replace(" ", "T")
                              ).getTime()
                              ? "Enter the end date to disable"
                              : "Enter the end date to enable"
                          
                            }
                           // value={selectAll?selectedEndTimeStamp:null}
                           //value={selectAll?selectedEndTimeStamp:(result.userid && userDates[result.userid]?userDates[result.userid].end_timestamp:"")}
                           value={(result.userid && userDates[result.userid]?userDates[result.userid].end_timestamp:"")}
                          onChange={(e)=>handleEndDatetimeChange(e,result.userid)}
                        ></input>
                      </td>

                      {


                        !userStatusId ?
                          (//   (enabledUsers[result.userid] != null
                            //     ? enabledUsers[result.userid]
                            //     : 
                            result.start_timestamp && currentTimestamp >=
                              new Date(
                                result.start_timestamp.replace(" ", "T")
                              ).getTime() && result.end_timestamp &&
                              currentTimestamp <
                              new Date(
                                result.end_timestamp.replace(" ", "T")
                              ).getTime() ? <td hidden={!permissionId}><div> <img src={activeUser} alt="Active" className="image" /></div></td> : <td hidden={!permissionId}> <img src={inactiveUser} alt="Inactive" className="image" /></td>) :



                          <td hidden={!permissionId}>
                            <Form.Check disabled={!userStatusId}
                              type="checkbox" //disabled={(result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime() && !selectedEndTimeStamp) || (result.end_timestamp && currentTimestamp >=  new Date(result.end_timestamp.replace(" ", "T")).getTime() && (!selectedEndTimeStamp || !selectedStartTimeStamp)) }
                              // checked={result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime()}
                              // checked={
                              //   enabledUsers[result.userid] != null
                              //     ? enabledUsers[result.userid]
                              //     : result.start_timestamp && currentTimestamp >=
                              //     new Date(
                              //       result.start_timestamp.replace(" ", "T")
                              //     ).getTime() && result.end_timestamp &&
                              //     currentTimestamp <
                              //     new Date(
                              //       result.end_timestamp.replace(" ", "T")
                              //     ).getTime()
                              // }
                              // onChange={() =>
                              //   handleCheckboxToggle(
                              //     result.userid,
                              //   result.start_timestamp && currentTimestamp >=
                              // new Date(
                              //   result.start_timestamp.replace(" ", "T")
                              // ).getTime() && 
                              //   result.end_timestamp &&
                              //   currentTimestamp <
                              //   new Date(
                              //     result.end_timestamp.replace(" ", "T")
                              //   ).getTime()
                              // )
                              // }

                              checked={selectedUsers.includes(result.userid)}
                              onChange={() => handleCheckboxChange(result.userid, result.start_timestamp && currentTimestamp >=
                                new Date(
                                  result.start_timestamp.replace(" ", "T")
                                ).getTime() &&
                                result.end_timestamp &&
                                currentTimestamp <
                                new Date(
                                  result.end_timestamp.replace(" ", "T")
                                ).getTime())}

                            // label={result.end_timestamp && currentTimestamp < new Date(result.end_timestamp.replace(" ", "T")).getTime()  ? "Enabled" : "Disabled"}
                            />
                          </td>
                      }
                    </tr>
                  ))}

                <tr hidden={!permissionId}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  
                </tr>

                <tr hidden={!permissionId || !userStatusId} className="updateButton">
                  <td></td>
                  <td></td>
                  
                  <td>
                    {" "}
                    <Button
                      variant="secondary"
                      onClick={handleFunctionalityUpdateButton}
                      disabled={Object.keys(userData).length === 0}

                    >
                      Update
                    </Button>
                  </td>
                  <td></td>
                 
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <p></p>
              <p>No users found.</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResultsModal;
