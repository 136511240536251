import React, { useState, useEffect, useRef } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Divider, Input, Select, Space, InputRef } from "antd";

function User_functionality(props) {
  const [loading, sendRequest] = useAxios();
  const [isError, setisError] = useState();
  const [isErrorMessage, setisErrorMessage] = useState();
  const [permission_name, setpermission_name] = useState();
  const [branch, setbranch] = useState();
  const [effective_designation, seteffective_designation] = useState();
  const [functionality, setfunctionality] = useState([]);
  const [functionalitylist, setfunctionalitylist] = useState();

  const [items, setItems] = useState();
  const [filter_permissiondata, setfilter_permissiondata] = useState();
  const [branch_list, setbranch_list] = useState([]);
  const [allbranch_list, setallbranch_list] = useState([]);
  const [
    effective_designation_list,
    seteffective_designation_list,
  ] = useState();
  const [name, setName] = useState("");
  const [entitylist, setentitylist] = useState([
    { ent_id: "E", ent_name: "Effective Designation" },
  ]);
  const [entity, setentity] = useState();
  const [permission_userlist, setpermission_userlist] = useState();
  const [permission_user, setpermission_user] = useState([]);
  const [functionality_userlist, setfunctionality_userlist] = useState();
  const [view_list, setview_list] = useState();
  const [selected_view, setselected_view] = useState([]);
  const [zonelist, setzonelist] = useState([]);
  const [selectedzone, setselectedzone] = useState();
  const [divisionlist, setdivisionlist] = useState([]);
  const [selecteddivision, setselecteddivision] = useState();
  const [clusterlist, setclusterlist] = useState([]);
  const [geographicalMapping, setGeographicalMapping] = useState([])
  const [selectedcluster, setselectedcluster] = useState();
  const [currentState, setCurrentState] = useState("HO");

  useEffect(() => {
    fetch_permission();
    geteffective_designation();
    getviewlist();
    //getzonelist();
    getheadoffice();
    getallbranch();
  }, []);
   // useEffect(() => {
  //   if (selectedcluster) {
  //     fetch_branchlist();
  //   }
  // }, [selectedcluster]);

  const getallbranch = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=getallbranches`,
        method: "GET",
        //data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("zone_list", response.data);
        setallbranch_list(response.data);        
      }
    );
  };
  // const getzonelist = () => {
  //   let apitoken = localStorage.getItem("api_token");
  //   sendRequest(
  //     {
  //       url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=zone`,
  //       method: "GET",
  //       //data: form,
  //       headers: {
  //         "api-token": apitoken,
  //       },
  //     },
  //     (response) => {
  //       console.log("zone_list", response.data);
  //       if (response.status != 0) {
  //         setzonelist(response.data);
  //       } else {
  //         setzonelist([]);
  //         alert(response.message);
  //       }
  //       setdivisionlist([]);
  //       setselecteddivision();
  //       setclusterlist([]);
  //       setselectedcluster();
  //       setbranch_list([]);
  //       setbranch();
  //     }
  //   );
  // };
  const getcluster = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=cluster&division_id=[${selecteddivision}]`,
        method: "GET",
        //data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("cluster_list", response.data);
        if (response.status != 0) {
          setGeographicalMapping(response.data.geographical_mapping);
          setbranch_list(response.data.branch_mapping)
          setCurrentState("Cluster")
          setselecteddivision([])
        } else {
          alert(response.message);
        }
        setbranch();
      }
    );
  };

  const getstate = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=state&ho_id=[${selecteddivision}]`,
        method: "GET",
        //data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("cluster_list", response.data);
        if (response.status != 0) {
          setGeographicalMapping(response.data.geographical_mapping);
          setbranch_list(response.data.branch_mapping)
          setCurrentState("State")
          setselecteddivision([])
        } else {
          alert(response.message);
        }
        setbranch();
      }
    );
  };

  const getdivision = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=division&state_id=[${selecteddivision}]`,
        method: "GET",
        //data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("cluster_list", response.data);
        if (response.status != 0) {
          setGeographicalMapping(response.data.geographical_mapping);
          setbranch_list(response.data.branch_mapping)
          setCurrentState("Division")
          setselecteddivision([])
        } else {
          alert(response.message);
        }
        setbranch();
      }
    );
  };
  const getheadoffice = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=ho`,
        method: "GET",
        //data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("ho_response", response.data.geographical_mapping);
        if (response.status != 0) {
          setGeographicalMapping(response.data.geographical_mapping);
          setbranch_list(response.data.branch_mapping);
        } else {
          // setdivisionlist([]);
          alert(response.message);
        }
      
        
        setbranch();
      }
    );
  };
  // const getdivision = () => {
  //   let apitoken = localStorage.getItem("api_token");
  //   sendRequest(
  //     {
  //       url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=division`,
  //       method: "GET",
  //       //data: form,
  //       headers: {
  //         "api-token": apitoken,
  //       },
  //     },
  //     (response) => {
  //       console.log("division_list", response.data);
  //       if (response.status != 0) {
  //         setdivisionlist(response.data);
  //       } else {
  //         setdivisionlist([]);
  //         alert(response.message);
  //       }
  //       setclusterlist([]);
  //       setselectedcluster();
  //       setbranch_list([]);
  //       setbranch();
  //     }
  //   );
  // };
  const getviewlist = async () => {
    let apitoken = localStorage.getItem("api_token");
    await sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/webhook/configuration/data`,
        method: "GET",
        //data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("view_list", response);
        setview_list(response);
      }
    );
  };
  const geteffective_designation = async () => {
    let apitoken = localStorage.getItem("api_token");
    await sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/designation/list`,
        method: "GET",
        //data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("deignations", response);
        seteffective_designation_list(response);
      }
    );
  };
  const fetch_branchlist = async () => {
    let apitoken = localStorage.getItem("api_token");
    await sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/data?flag=branch&cluster_id=[${selecteddivision}]`,
        method: "GET",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("branch_list", response.data);
        if (response.status != 0) {
          setbranch_list([])
          setbranch_list(response.data.branch_mapping);
          setselecteddivision([])
        } else {
          alert(response.message);
        }
        setbranch()
      }
    );
  };
  const fetch_permission = async () => {
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();
    form.append("flag", "fetch_permission");
    //form.append("role_id", role_functionality_id);
    //form.append("functionality_arr", JSON.stringify(functionality));
    await sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("response", response);
        setItems(response.permissions);
      }
    );
  };

  const inputRef = useRef();
  let index = 0;
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    const newItem = {
      conditions: [],
      permission_id: "",
      permission_name: name,
    };
    setItems([...items, newItem]);
    setName("");
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  const getEffectiveDesignationOrDepartment = (e) => {
    setentity(e);
    let permissionName = permission_name;
    let branchId = branch;
    let type = e;
    // Find the permission by permission_name
    const permission = items.find(
      (item) => item.permission_name === permissionName
    );

    if (!permission) {
      return "Permission not found";
    }

    // Filter the conditions by branch_id
    const conditions = permission.conditions.filter(
      (condition) => condition.branch_id === branchId
    );

    if (conditions.length === 0) {
      return "No conditions found for the given branch_id";
    }

    // Based on type, return effective_designation or department
    console.log("items--", type);
    if (type == "E") {
      // Map the effective_designation from the filtered conditions
      const effectiveDesignations = conditions
        .map(
          (condition) =>
            condition.effctive_designation || condition.effective_designation
        )
        .flat()
        .filter(Boolean);
      seteffective_designation(effectiveDesignations);
      // return effectiveDesignations.length
      //   ? effectiveDesignations
      //   : "No effective designations found";
    } else if (type === "D") {
      // Map the department from the filtered conditions
      const departments = conditions
        .map((condition) => condition.department)
        .flat()
        .filter(Boolean);
      return departments.length ? departments : "No departments found";
    } else {
      return "Invalid type";
    }
  };

  const save_functionality = async () => {
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();
    form.append("flag", "save_user_functionality");
    form.append(
      "permissions",
      permission_name ? JSON.stringify([permission_name]) : []
    );
    form.append("user_status", "A");
    form.append(
      "functionalities",
      functionality ? JSON.stringify(functionality) : []
    );
    form.append("views", selected_view ? JSON.stringify(selected_view) : []);
    form.append(
      "userid",
      permission_user ? JSON.stringify(permission_user) : []
    );

    const response = await sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setisError(true);
        setisErrorMessage(response.message);
        fetch_permission();
      }
    );
  };
  const save_permission = async () => {
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();
    form.append("flag", "save_permission");
    form.append("permission_name", permission_name);
    form.append(
      "conditions",
      JSON.stringify([
        {
          branch_id: branch,
          status: "A",
          effective_designation: effective_designation,
        },
      ])
    );
    const response = await sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setisError(true);
        setisErrorMessage(response.message);
        fetch_permission();
      }
    );
  };
  const get_functionality_data = () => {
    setpermission_name();
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();
    form.append("flag", "get_functionality");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        const uniqueElements = response.functionality.reduce((acc, current) => {
          const x = acc.find(
            (item) => item.function_name === current.function_name
          );
          if (!x && current.function_name) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        console.log("get_functionality_data", uniqueElements);
        setfunctionalitylist(uniqueElements);
      }
    );
  };
  const get_permissiondata = (e) => {
    setpermission_name(e);
    const filteredPermissions = items.filter(
      (permission) => permission.permission_name === e
    );
    console.log("filteredPermissions", filteredPermissions);
    setfilter_permissiondata(filteredPermissions);
  };

  const get_branch_name = (branch_id) => {
    const branch = allbranch_list.find((branch) => branch.branch_id === branch_id);
    return branch ? branch.branch_name : "Branch not found";
  };

  const get_effectivedesi_name = (ids) => {
    console.log("names->", ids);
    if (!Array.isArray(ids)) {
      return "";
    }

    const names = ids.map((id) => {
      const effectivedesi = effective_designation_list.find(
        (effectivedesi) => effectivedesi.effective_designation_id === id
      );
      return effectivedesi ? effectivedesi.designation_name : "";
    });
    return names.filter((name) => name !== "").join(", ");
  };

  const handleFetchMapping = () => {
    // setbranch_list([])    
    if (selecteddivision.length>0 && currentState=='HO' ){
      getstate()
    }
    if (selecteddivision.length>0 && currentState=='State' ){
      getdivision()
    }
    if (selecteddivision.length>0 && currentState=='Division' ){
      getcluster()
    }
    if (selecteddivision.length>0 && currentState=='Cluster'){
      fetch_branchlist()
    }
    
  }

  const get_permission_user = (e) => {
    setpermission_name(e);
    setpermission_user();
    setfunctionality();
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();
    form.append("flag", "get_all_users_for_permission");
    form.append("permission_id", e);
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("get_permission_user", response);
        setpermission_userlist(response.users);
      }
    );

    const form1 = new FormData();
    form1.append("flag", "fetch_permission_user_functionality");
    form1.append("permission_id", e);
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data: form1,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        // const userIds = response.puf_mapping.flatMap((puf) =>
        //   puf.user_functionality.map((userFunc) => userFunc.userid)
        // );

        const userFunctionalityMap = response.puf_mapping.flatMap((puf) =>
          puf.user_functionality.map((userFunc) => ({
            userid: userFunc.userid,
            functionalitys: userFunc.functionalitys,
          }))
        );

        setfunctionality_userlist(userFunctionalityMap);
        //setpermission_userlist(response.users);
      }
    );
  };
  useEffect(() => {
    const matchedFunctionalities =
      functionality_userlist &&
      functionality_userlist
        .filter(
          (userFunc) =>
            permission_user && permission_user.includes(userFunc.userid)
        )
        .flatMap((userFunc) => userFunc.functionalitys);

    console.log("matchedFunctionalities", matchedFunctionalities);
    setfunctionality(matchedFunctionalities);
  }, [permission_user]);
  console.log("items", permission_user);
  return (
    <div className="container">
      <Commonheader pagetitle={"Permission"} props={props} />
      {loading && (
        <div class="load-4">
          <div class="ring-1"></div>
        </div>
      )}
      <div
        className={
          isError
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisError(false);
                window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{isErrorMessage}</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                  //onClick={() => setuser_role("")}
                >
                  Permission
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="row">
                  <div className="col-md-3 mt-1">
                    <Select
                      className="form-control"
                      placeholder="Select Permission"
                      onChange={(e) => get_permissiondata(e)}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              placeholder="Enter Permission Name"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              disabled={!name}
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addItem}
                            ></Button>
                          </Space>
                        </>
                      )}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={
                        items &&
                        items.map((item) => ({
                          label: item.permission_name,
                          value: item.permission_name,
                        }))
                      }
                    />
                  </div>
                  {/* <div className="col-md-3 mt-1">
                    <Select
                      mode="tags"
                      className="form-control"
                      value={selectedzone}
                      tokenSeparators={[","]}
                      onChange={(value) => {
                        if (value.includes("all")) {
                          const allBranchIds =
                            zonelist && zonelist.map((item) => item.zone_id);
                          setselectedzone(allBranchIds);
                        } else {
                          const selectedValues = value
                            .map((val) => {
                              if (typeof val === "string") {
                                const foundOption = zonelist.find(
                                  (item) =>
                                    item.zone_name.toLowerCase() ===
                                    val.toLowerCase()
                                );
                                return foundOption ? foundOption.zone_id : null;
                              }
                              return val; // If `val` is not a string, return it as is
                            })
                            .filter((val) => val !== null);
                          setselectedzone(selectedValues);
                        }
                      }}
                      placeholder="Select Zone"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={[
                        {
                          label: "- All -",
                          value: "all",
                        },
                        ...(zonelist
                          ? zonelist.map((item) => ({
                              label: item.zone_name,
                              value: item.zone_id,
                            }))
                          : []),
                      ]}
                    />
                  </div> */}
                  <div className="col-md-3 mt-1">
                    <Select
                      mode="tags"
                      className="form-control"
                      value={selecteddivision}                      
                      tokenSeparators={[","]}
                      onChange={(value) => {
                        if (value.includes("all")) {
                          const allBranchIds =
                            geographicalMapping
                            &&
                            geographicalMapping.map((item) => {
                              const keys = Object.keys(item)
                              return item[keys[0]]
                            }
                              );
                          setselecteddivision(allBranchIds);
                        } else {
                          const selectedValues = value
                            .map((val) => {
                              if (typeof val === "string") {
                                const foundOption = geographicalMapping.find(
                                  (item) =>{
                                    const keys = Object.keys(item)
                                    return item[keys[1]].toLowerCase() ===
                                    val.toLowerCase()

                                  }
                                );
                                return foundOption
                                  ? (() => {
                                      const keys = Object.keys(foundOption);
                                      return foundOption[keys[0]] ; 
                                    })()
                                  : null;
                              }
                              return val; // If `val` is not a string, return it as is
                            })
                            .filter((val) => val !== null);
                            console.log("selected values ... ",selectedValues);
                          setselecteddivision(selectedValues);
                        }
                      }}
                      placeholder="Select Division"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={[
                        {
                          label: "- All -",
                          value: "all",
                        },
                        ...(geographicalMapping
                          ? geographicalMapping.map((item) => {
                              const keys = Object.keys(item)
                              return{
                                label: item[keys[1]],
                                value: item[keys[0]]
                              }
                            })
                          : []),
                      ]}
                    />
                  </div>
                  <div className="col-md-3 mt-1">
                    <Select
                      mode="tags"
                      className="form-control"
                      value={branch}
                      disabled={branch_list.length == 0}
                      tokenSeparators={[","]}
                      onChange={(value) => {
                        if (value.includes("all")) {
                          const allBranchIds =
                            branch_list &&
                            branch_list.map((item) => {
                              const keys = Object.keys(item)
                              return item[keys[0]]
                            });
                          setbranch(allBranchIds);
                        } else {
                          const selectedValues = value
                            .map((val) => {
                              if (typeof val === "string") {
                                const foundOption = branch_list.find(
                                  (item) =>
                                    {
                                      const keys = Object.keys(item)
                                      return item[keys[1]].toLowerCase() ===
                                      val.toLowerCase()
  
                                    }
                                );
                                return foundOption
                                  ? (() => {
                                      const keys = Object.keys(foundOption);
                                      return foundOption[keys[0]] ; 
                                    })()
                                  : null;
                              }
                              return val; // If `val` is not a string, return it as is
                            })
                            .filter((val) => val !== null);
                          setbranch(selectedValues);
                        }
                      }}
                      placeholder="Select Branch"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={[
                        {
                          label: "- All -",
                          value: "all",
                        },
                        ...(branch_list
                          ? branch_list.map((item) => {
                              const keys = Object.keys(item)
                              return{
                                label: item[keys[1]],
                                value: item[keys[0]]
                              }
                              
                            })
                          : []),
                      ]}
                    />
                  </div>
                  <div>
                    <Button type="primary" onClick={handleFetchMapping} ghost>fetch mapping</Button>
                  </div>
                  <div className="col-md-3 mt-1">
                    <Select
                      className="form-control"
                      placeholder="Select Entity"
                      onChange={(e) => {
                        getEffectiveDesignationOrDepartment(e);
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={
                        entitylist &&
                        entitylist.map((item) => ({
                          label: item.ent_name,
                          value: item.ent_id,
                        }))
                      }
                    />
                  </div>
                  {entity == "E" && (
                    <div className="col-md-3 mt-1">
                      <Select
                        mode="tags"
                        className="form-control"
                        value={effective_designation}
                        tokenSeparators={[","]}
                        onChange={(value) => {
                          if (value.includes("all")) {
                            const allBranchIds =
                              effective_designation_list &&
                              effective_designation_list.map(
                                (item) => item.effective_designation_id
                              );
                              seteffective_designation(allBranchIds);
                          } else {
                            const selectedValues = value
                              .map((val) => {
                                if (typeof val === "string") {
                                  const foundOption = effective_designation_list.find(
                                    (item) =>
                                      item.designation_name +
                                        " - " +
                                        item.designation_code.toLowerCase() ===
                                      val.toLowerCase()
                                  );
                                  return foundOption
                                    ? foundOption.effective_designation_id
                                    : null;
                                }
                                return val; // If `val` is not a string, return it as is
                              })
                              .filter((val) => val !== null);
                              seteffective_designation(selectedValues);
                          }
                        }}
                        placeholder="Select Effective Designation"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option && option.label
                            ? option.label.toLowerCase()
                            : ""
                          ).includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA && optionA.label
                            ? optionA.label.toLowerCase()
                            : ""
                          ).localeCompare(
                            optionB && optionB.label
                              ? optionB.label.toLowerCase()
                              : ""
                          )
                        }
                        options={[
                          {
                            label: "- All -",
                            value: "all",
                          },
                          ...(effective_designation_list
                            ? effective_designation_list.map((item) => ({
                                label:
                                  item.designation_name +
                                  " - " +
                                  item.designation_code,
                                value: item.effective_designation_id,
                              }))
                            : []),
                        ]}
                      />

                      {/* <Select
                        className="form-control"
                        mode="multiple"
                        value={effective_designation}
                        onChange={(e) => seteffective_designation(e)}
                        placeholder="Select Effective Designation"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option && option.label
                            ? option.label.toLowerCase()
                            : ""
                          ).includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA && optionA.label
                            ? optionA.label.toLowerCase()
                            : ""
                          ).localeCompare(
                            optionB && optionB.label
                              ? optionB.label.toLowerCase()
                              : ""
                          )
                        }
                        options={
                          effective_designation_list &&
                          effective_designation_list.map((item) => ({
                            label:
                              item.designation_name +
                              " - " +
                              item.designation_code,
                            value: item.effective_designation_id,
                          }))
                        }
                      /> */}
                    </div>
                  )}
                  <div className="col-md-2 mt-1">
                    <button
                      className="btn btn-primary form-control"
                      onClick={() => save_permission()}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Branch Name</th>
                        <th>Status</th>
                        <th>Effective Designation</th>
                        {/* <th>Department</th>
                        <th>User ID</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {filter_permissiondata &&
                        filter_permissiondata[0].conditions.map(
                          (condition, index) => (
                            <tr key={`${index}`}>
                              <td>{index + 1}</td>
                              <td>{get_branch_name(condition.branch_id)}</td>
                              <td>{condition.status}</td>
                              <td>
                                {condition.effective_designation &&
                                  get_effectivedesi_name(
                                    condition.effective_designation
                                  )}
                              </td>
                              {/* <td>
                              {condition.department
                                ? condition.department.join(", ")
                                : "N/A"}
                            </td>
                            <td>
                              {condition.userid
                                ? condition.userid.join(", ")
                                : "N/A"}
                            </td> */}
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4">
                  {/* <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user_role_list &&
                        user_role_list.map((roles, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{roles.rolename}</td>
                            <td>
                              {index == EditRole ? (
                                <>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      setEditRoleStatus(e.target.value)
                                    }
                                  >
                                    <option value="A">Active</option>
                                    <option value="X">De Active</option>
                                  </select>
                                </>
                              ) : (
                                "Active"
                              )}
                            </td>
                            <td>
                              {isEditRole ? (
                                <>
                                  <button
                                    className="btn btn-primary mr-2"
                                    onClick={() => {
                                      //update_role();
                                      issetEditRole(false);
                                    }}
                                  >
                                    <CheckOutlined />
                                  </button>
                                  <button
                                    className="btn btn-primary mr-2"
                                    onClick={() => {
                                      setEditRole("");
                                      issetEditRole(false);
                                    }}
                                  >
                                    <CloseOutlined />
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="btn btn-primary mr-1"
                                  onClick={() => {
                                    setEditRole(index);
                                    setuser_role(roles.rolename);
                                    issetEditRole(true);
                                  }}
                                >
                                  <EditOutlined disabled={EditRole > 0} />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  onClick={() => get_functionality_data()}
                >
                  User Functionality
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="row">
                  <div className="col-md-3 mt-1">
                    <Select
                      className="form-control"
                      placeholder="Select Permission"
                      onChange={(e) => get_permission_user(e)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={
                        items &&
                        items.map((item) => ({
                          label: item.permission_name,
                          value: item.permission_id,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-3 mt-1">
                    <Select
                      className="form-control"
                      mode="tags"
                      tokenSeparators={[","]}
                      placeholder="Select User"
                      value={permission_user}
                      onChange={(value) => {
                        if (value.includes("all")) {
                          const allUserIds = permission_userlist.map(
                            (item) => item.userid
                          );
                          setpermission_user(allUserIds);
                        } else {
                          const selectedValues = value
                            .map((val) => {
                              if (typeof val === "string") {
                                const foundOption = permission_userlist.find(
                                  (item) =>
                                    item.employee_code.toLowerCase() ===
                                    val.toLowerCase()
                                );
                                return foundOption ? foundOption.userid : null;
                              }
                              return val; // If `val` is not a string, return it as is
                            })
                            .filter((val) => val !== null);
                          setpermission_user(selectedValues);
                        }
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={[
                        {
                          label: "- All -",
                          value: "all",
                        },
                        ...(permission_userlist
                          ? permission_userlist.map((item) => ({
                              label: `${item.employee_code} - ${item.designation_name}`,
                              value: item.userid,
                            }))
                          : []),
                      ]}
                    />
                  </div>
                  <div className="col-md-3 mt-1">
                    <Select
                      className="form-control"
                      mode="multiple"
                      placeholder="Select Functionality"
                      value={functionality}
                      onChange={(e) => setfunctionality(e)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={
                        functionalitylist &&
                        functionalitylist.map((item) => ({
                          label: item.function_name,
                          value: item.functionality_id,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-3 mt-1">
                    <Select
                      className="form-control"
                      mode="multiple"
                      placeholder="Select View"
                      value={selected_view}
                      onChange={(e) => setselected_view(e)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option && option.label
                          ? option.label.toLowerCase()
                          : ""
                        ).includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA && optionA.label
                          ? optionA.label.toLowerCase()
                          : ""
                        ).localeCompare(
                          optionB && optionB.label
                            ? optionB.label.toLowerCase()
                            : ""
                        )
                      }
                      options={
                        view_list &&
                        view_list.map((item) => ({
                          label: item.view_name,
                          value: item.view_id,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-2 mt-2">
                    <button
                      className="btn btn-primary form-control"
                      onClick={() => save_functionality()}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  {/* <table className="table">
                    <thead>
                      <tr>
                        <th>Functionality</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {role_functionalitylist &&
                            role_functionalitylist.map((mapping) =>
                              mapping.functionality_arr.map(
                                (func) => func.functionality_name + ", "
                              )
                            )}
                        </td>
                        <td>
                          {functionality &&
                            (isEditFunctionality ? (
                              <>
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    setEditFunctionalityStatus(e.target.value)
                                  }
                                >
                                  <option value="A">Active</option>
                                  <option value="X">De Active</option>
                                </select>
                              </>
                            ) : (
                              "Active"
                            ))}
                        </td>
                        <td>
                          {functionality &&
                            (isEditFunctionality && role_functionalitylist ? (
                              <>
                                <button
                                  className="btn btn-primary mr-2"
                                  onClick={() => {
                                    //update_functionality();
                                    setisEditFunctionality(false);
                                  }}
                                >
                                  <CheckOutlined />
                                </button>
                                <button
                                  className="btn btn-primary mr-2"
                                  onClick={() => {
                                    setEditRole("");
                                    setisEditFunctionality(false);
                                  }}
                                >
                                  <CloseOutlined />
                                </button>
                              </>
                            ) : (
                              <button
                                className="btn btn-primary mr-1"
                                onClick={() => {
                                  //setEditRole(index);
                                  //setuser_role(roles.rolename);
                                  setisEditFunctionality(true);
                                }}
                              >
                                <EditOutlined />
                              </button>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User_functionality;
