import useAxios from "./useAxios";

export function useLogout() {
  const [loading, sendRequest] = useAxios();

  const logout = () => {
    let apitoken = localStorage.getItem("api_token");

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/new/logout`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      }
    )
      .then((res) => {
        console.log(res);
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        window.location.href = "/";
      });
  };

  return [logout, loading];
}
